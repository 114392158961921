import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import FilterField from '../../common/FilterField';
import DateSinglePicker from '../../common/DateSinglePicker';

import { changeS2CFilters } from '../../../state/s2c/actions';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
  },
}));

const S2CFilter = ({
  filters,
  suppliers,
  products,
  cities,
  allShipTypes,
  allLocationTypes,
  setFilters,
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
        <FilterField
          label="Supplier"
          labelKey="Supplier Name"
          choices={suppliers || []}
          value={filters.supplier}
          onChange={(e, value) => setFilters('supplier', value)}
        />
        <FilterField
          label="Pick Up City"
          labelKey="city"
          choices={cities || []}
          value={filters.city}
          onChange={(e, value) => setFilters('city', value)}
        />
        <DateSinglePicker
          value={filters.date}
          onChange={(value) => setFilters('date', value)}
        />
        <FilterField
          label="Purchase Product"
          choices={products || []}
          value={filters.product}
          onChange={(e, value) => setFilters('product', value)}
        />
        <FilterField
          label="Ship To Products"
          choices={allShipTypes || []}
          value={filters.shipType}
          onChange={(e, value) => setFilters('shipType', value)}
        />
        <FilterField
          label="Potential Ship To Location Type"
          choices={allLocationTypes || []}
          value={filters.locationType}
          onChange={(e, value) => setFilters('locationType', value)}
        />
    </Paper>
  )
}

const mapStateToProps = state => ({
  filters: state.s2c.filters,
  suppliers: state.s2c.suppliers,
  products: state.s2c.products,
  cities: state.s2c.cities,
  allShipTypes: state.base.allShipTypes,
  allLocationTypes: state.base.allLocationTypes
});

const mapDispatchToProps = dispatch => ({
  setFilters: (type, value) => {
    return dispatch(changeS2CFilters(type, value))
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(S2CFilter);
