import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '33.3%',
    padding: theme.spacing(1),
    minWidth: 120,
    boxSizing: 'border-box',
  },
}));

const FilterField = ({className, choices, label, labelFormat, labelKey, value, onChange, disabled, multiple}) => {
  const classes = useStyles();

  return (
    <FormControl className={`${classes.formControl} ${className}`}>
      <Autocomplete
        multiple={multiple}
        options={[value, ...choices]}
        getOptionLabel={(option) => {
          if (labelFormat) { // when label needs to be transformed from value
            return labelFormat(option)
          } else if (labelKey) { // when value is object
            return option ? option[labelKey] : ""
          } else if (multiple && typeof option === 'object') { // when multi select
            if (option.length > 0) {
              return option[0]
            } else {
              return ""
            }
          } else { // when value is string
            return option || "";
          }
        }}
        style={{ width: '100%' }}
        onChange={onChange}
        value={value}
        disabled={disabled}
        renderInput={(params) => (
          <>
            <TextField {...params} label={label} variant="outlined" />
          </>
        )}
      />
    </FormControl>
  );
};

export default FilterField;
