import _ from 'lodash'
import * as ActionTypes from './actionTypes'

const initialState = {
  loading: false,
  locations: null,
  markers: null,
  lines: null,
  suppliers: null,
  products: null,
  cities: null,
  filters: {
    product: null,
    supplier: null,
    city: null,
    shipType: null,
    locationType: null,
    date: null,
  },
  costsChartData: null
}

const reducer = (state = initialState, action) => {
  let newState = _.cloneDeep(state)

  switch (action.type) {
    case ActionTypes.S2C_API_LOADING:
      newState.loading = true
      return newState

    case ActionTypes.S2C_API_FAILED:
      newState.loading = false
      return newState

    case ActionTypes.CHANGE_S2C_FILTERS: {
      const newFilters = _.cloneDeep(newState.filters)
      newFilters[action.payload.type] = action.payload.value
      newState.filters = newFilters
      return newState
    }

    case ActionTypes.CLEAR_S2C_DATA:
      return {
        ...newState,
        ...action.payload
      }

    case ActionTypes.GET_S2C_LOCATIONS_SUCCESS:
      newState.loading = false
      newState.locations = action.payload.locations
      newState.markers = action.payload.markers
      newState.lines = action.payload.lines
      return newState

    case ActionTypes.GET_S2C_SUPPLIERS_SUCCESS:
      newState.loading = false
      newState.suppliers = action.payload
      return newState

    case ActionTypes.GET_S2C_PRODUCTS_SUCCESS:
      newState.loading = false
      newState.products = action.payload
      if (action.payload && action.payload.length === 1) {
        newState.filters.product = action.payload[0]
      }
      return newState

    case ActionTypes.GET_S2C_CITIES_SUCCESS: {
      newState.loading = false
      newState.cities = action.payload
      if (action.payload.length === 1) {
        newState.filters.city = action.payload[0]
      }
      return newState
    }

    case ActionTypes.GET_S2C_COSTS_CHART_DATA:
      newState.loading = false
      newState.costsChartData = action.payload
      return newState

    case ActionTypes.GET_S2C_PRICES_CHART_DATA:
      newState.loading = false
      return newState

    default:
      return state
  }
}

export default reducer
