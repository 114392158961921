import React from "react";
import moment from 'moment'
import { makeStyles } from "@material-ui/core/styles";
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer, ReferenceArea
} from 'recharts';
import { getDynamicHexColorFromText } from '../../../utils/functions'

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '33.3%',
    padding: theme.spacing(1),
    minWidth: 120,
    boxSizing: 'border-box',
  },
  legendText: {
    fontSize: 12,
    color: '#0c244280'
  },
  tooltip: {
    backgroundColor: '#101C2A',
    borderRadius: 4,
    fontSize: 12,
    color: 'white',
    padding: theme.spacing(1),
  }
}));

const PricesLineChart = (props) => {
  // eslint-disable-next-line
  const classes = useStyles();
// eslint-disable-next-line
  const CustomTooltip = (tooltipProps) => {
    const { active } = tooltipProps
    if (active) {
      const { payload } = tooltipProps
      return (
        payload  &&
        <div className={classes.tooltip}>
          {payload[0] && `${payload[0].name} : $${payload[0].value}`}<br/>
          {payload[1] && `${payload[1].name} : $${payload[1].value}`}
        </div>
      );
    }

    return null;
  }

  const lastDate = props.prices.chartData.slice(-1)[0]?.date

  return (
    <ResponsiveContainer height={240}>
      <LineChart
        data={props.prices.chartData}
        margin={{
          top: 5, right: 5, left: 5, bottom: 5,
        }}
      >
        <CartesianGrid vertical={false} strokeDasharray="1" />
        <XAxis 
          tick={{fontSize: 12, fill: '#CDD0DA'}}
          tickLine={false}
          dataKey="date"
        />
        <YAxis
          tick={{fontSize: 12, fill: '#CDD0DA'}}
          tickLine={false}
          axisLine={false}
          tickFormatter={price => `$ ${price}`}
        />
        {/* <Tooltip content={<CustomTooltip/>}/> */}
        {
          props.prices.customerNos.map(customerNo => (
            <Line
              key={`${customerNo}_unit`}
              type="monotone"
              dataKey={`${customerNo}_unit`}
              stroke={getDynamicHexColorFromText(customerNo)}
              strokeWidth={2}
              dot={false}
            />
          ))
        }
        {
          props.prices.customerNos.map(customerNo => (
            <Line
              key={`${customerNo}_forecast`}
              type="monotone"
              dataKey={`${customerNo}_forecast`}
              stroke={getDynamicHexColorFromText(customerNo)}
              strokeWidth={2}
              dot={false}
            />
          ))
        }
        <ReferenceArea x1={moment().format('MM/DD/YYYY')} x2={lastDate} fill="#F5F5F5" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default PricesLineChart;
