import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import PenIcon from '@material-ui/icons/CreateOutlined';

import LoadingWrapper from '../../common/LoadingWrapper';
import FilterField from '../../common/FilterField';
import MatrixFilter from './MatrixFilter';
import MatchTable from './MatchTable';
import {
  clearOptimizedMatches,
  getOptimizedMatches,
  getPickupProducts,
  getShiptoProducts,
  getScenarios,
} from '../../../state/matrix/actions';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '90%',
    margin: '0 auto',
  },
  paper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  paperWrapper: {
    marginTop: theme.spacing(2),
  },
  hideFilter: {
    height: 0,
    overflow: 'hidden',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    '& h2': {
      margin: 0,
    },
  },
}));

const MatrixOptimizeScreen = ({
  loading,
  matches,
  pickupProducts,
  shiptoProducts,
  scenarios,
  clearOptimizedMatches,
  getOptimizedMatches,
  getPickupProducts,
  getShiptoProducts,
  getScenarios,
}) => {
  const classes = useStyles();
  const [scenario, setScenario] = useState(null);
  const [matrixFilters, setMatrixFilters] = useState({
    pkProduct: null,
    spProduct: null,
    pkDate: null,
    deliveryDate: null,
    loadType: null,
    pkLocationType: null,
    spLocationType: null,
  });
  const [hiddenFilter, hideFilter] = useState(true);

  useEffect(() => {
    document.title = 'WWS Trading - Matrix';
    !pickupProducts && getPickupProducts();
    !shiptoProducts && getShiptoProducts();
    getScenarios();
    clearOptimizedMatches();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetchData = async (scenario) => {
      if (scenario) {
        getOptimizedMatches({ scenario });
      } else {
        clearOptimizedMatches();
      }
    };

    fetchData(scenario);
    // eslint-disable-next-line
  }, [scenario]);

  const handleFiltersChange = (type, value) => {
    setMatrixFilters({
      ...matrixFilters,
      [type]: value,
    });
  };

  const isFiltered = Object.keys(matrixFilters).reduce(
    (acc, cur) => acc || matrixFilters[cur] !== null,
    false
  );

  const filteredMatches =
    matches &&
    matches.filter((m) => {
      const { pkProduct, spProduct, pkDate, deliveryDate } = matrixFilters;
      return (
        (pkProduct && pkProduct === m.pkProduct) ||
        (spProduct && spProduct === m.spProduct) ||
        (pkDate &&
          moment(m.pkDate).isSameOrAfter(pkDate.startDate) &&
          moment(m.pkDate).isSameOrBefore(pkDate.endDate)) ||
        (deliveryDate &&
          moment(m.deliveryDate).isSameOrAfter(deliveryDate.startDate) &&
          moment(m.deliveryDate).isSameOrBefore(deliveryDate.endDate))
      );
    });
  const data = isFiltered ? filteredMatches : matches;

  return (
    <LoadingWrapper visible={loading}>
      <div className={classes.container}>
        <Grid container spacing={3}>
          <Grid className={classes.paperWrapper} item xs={12}>
            <Paper className={classes.paper}>
              <Box className={classes.header}>
                <Box display="flex" alignItems="center">
                  <h2>Optimized Trades {isFiltered && '(Filtered)'}</h2>
                  {hiddenFilter && (
                    <IconButton
                      size="small"
                      color="secondary"
                      onClick={() => hideFilter(false)}
                    >
                      <PenIcon />
                    </IconButton>
                  )}
                </Box>
              </Box>
              <Box className={hiddenFilter ? classes.hideFilter : null}>
                <MatrixFilter
                  filters={matrixFilters}
                  changeFilters={handleFiltersChange}
                  hide={() => hideFilter(true)}
                />
              </Box>
            </Paper>
          </Grid>
          <Grid className={classes.paperWrapper} item xs={12}>
            <FilterField
              label="Saved Scenario"
              choices={scenarios || []}
              labelFormat={(option) =>
                option
                  ? `${option.Name} (${moment(option.DateRun).format(
                      'MM-DD-YYYY'
                    )})`
                  : ''
              }
              value={scenario}
              onChange={(e, value) => setScenario(value)}
            />
            <MatchTable data={data} />
          </Grid>
        </Grid>
      </div>
    </LoadingWrapper>
  );
};

const mapStateToProps = (state) => ({
  loading: state.matrix.loading,
  matches: state.matrix.optimizedMatches,
  pickupProducts: state.matrix.pickupProducts,
  shiptoProducts: state.matrix.shiptoProducts,
  scenarios: state.matrix.scenarios,
});

const mapDispatchToProps = (dispatch) => ({
  getOptimizedMatches: (matrixFilters) => {
    return dispatch(getOptimizedMatches(matrixFilters));
  },
  getPickupProducts: () => {
    dispatch(getPickupProducts());
  },
  getShiptoProducts: () => {
    dispatch(getShiptoProducts());
  },
  getScenarios: () => {
    return dispatch(getScenarios());
  },
  clearOptimizedMatches: () => {
    return dispatch(clearOptimizedMatches());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MatrixOptimizeScreen);
