import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
  formControl: {
    padding: theme.spacing(1),
    minWidth: 120,
  },
}));

const InputField = (props) => {
  const classes = useStyles();
  return (
    <FormControl className={`${classes.formControl} ${props.className}`}>
      <TextField {...props} />
    </FormControl>
  );
};

export default InputField;
