import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputField from '../../elements/InputField';

const useStyles = makeStyles((theme) => ({
  dialog: {
    // maxWidth: 860,
    overflowY: 'visible',
  },
  title: {
    '& h2': {
      fontSize: 23,
      fontWeight: 'bold',
      textAlign: 'center',
      marginTop: theme.spacing(2.5),
    },
  },
  close: {
    position: 'absolute',
    top: theme.spacing(1.5),
    left: theme.spacing(2),
  },
  actions: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& button': {
      minWidth: 130,
      marginBottom: theme.spacing(2),
      marginLeft: 0,
    },
  },
  formControl: {
    width: '50%',
    padding: theme.spacing(1),
    minWidth: 120,
    boxSizing: 'border-box',
  },
  halfField: {
    width: '50%',
  },
  fullField: {
    width: '100%',
  },
  spinner: {
    color: 'white',
    margin: 2
  }
}));

const ScenarioModal = ({ show, name, setName, onCancel, onSave, saving }) => {
  const classes = useStyles();
  return (
    <Dialog
      className={classes.dialog}
      open={show}
      onClose={onCancel}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" className={classes.title}>
        Optimization Scenario
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please name this optimization run so that it can be accessed later.
        </DialogContentText>
        <InputField
          className={classes.fullField}
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={onSave} variant="contained" color="secondary">
          {saving ? <CircularProgress className={classes.spinner} size={20} /> : 'Save and Run'}
        </Button>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScenarioModal;
