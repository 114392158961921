import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import PenIcon from '@material-ui/icons/CreateOutlined';

import LoadingWrapper from '../../common/LoadingWrapper';
import MatrixFilter from './MatrixFilter';
import {
  getPotentialMatches,
  getPickupProducts,
  getShiptoProducts,
  excludePontentialMatch,
  includePontentialMatch,
} from '../../../state/matrix/actions';
import SortableTable from '../../common/SortableTable';

const headCells = [
  {
    id: 'pkLocation',
    numeric: false,
    disablePadding: false,
    label: 'Pickup Location',
  },
  {
    id: 'pkVendor',
    numeric: false,
    disablePadding: false,
    label: 'Pickup Vendor',
  },
  {
    id: 'pkAddress',
    numeric: false,
    disablePadding: false,
    label: 'Pickup City',
  },
  {
    id: 'pkProduct',
    numeric: false,
    disablePadding: false,
    label: 'Pickup Product',
  },
  {
    id: 'spLocation',
    numeric: false,
    disablePadding: false,
    label: 'Ship To Location',
  },
  {
    id: 'spCustomer',
    numeric: false,
    disablePadding: false,
    label: 'Ship To Customer',
  },
  {
    id: 'spAddress',
    numeric: false,
    disablePadding: false,
    label: 'Ship To City',
  },
  {
    id: 'spProduct',
    numeric: false,
    disablePadding: false,
    label: 'Ship To Product',
  },
  {
    id: 'excluded',
    numeric: false,
    disablePadding: false,
    label: 'Excluded?',
    toggleOnLabel: 'Included',
    toggleOffLabel: 'Excluded',
  },
];

const useStyles = makeStyles((theme) => ({
  container: {
    width: '90%',
    margin: '0 auto',
  },
  paper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  paperWrapper: {
    marginTop: theme.spacing(2),
  },
  hideFilter: {
    height: 0,
    overflow: 'hidden',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    '& h2': {
      margin: 0,
    },
  },
  excludeBtn: {
    marginBottom: theme.spacing(2),
  },
}));

const PotentialPage = ({
  loading,
  matches,
  pickupProducts,
  shiptoProducts,
  getPotentialMatches,
  getPickupProducts,
  getShiptoProducts,
  excludePontentialMatch,
  includePontentialMatch,
}) => {
  const classes = useStyles();
  const [matrixFilters, setMatrixFilters] = useState({
    showExcluded: true,
    pickupProduct: null,
    shiptoProduct: null,
  });
  const [hiddenFilter, hideFilter] = useState(true);

  useEffect(() => {
    !pickupProducts && getPickupProducts();
    !shiptoProducts && getShiptoProducts();
    document.title = 'WWS Trading - Potential Matches';
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getPotentialMatches(matrixFilters);
    // eslint-disable-next-line
  }, [matrixFilters]);

  const handleFiltersChange = (type, value) => {
    setMatrixFilters({
      ...matrixFilters,
      [type]: value,
    });
  };

  const handleExclusionChange = (event) => {
    const exclusion = {
      VendNo: event.data.pkVendor,
      OrdNo: event.data.pkAddress,
      SalesProduct: event.data.pkProduct,
      CustNo: event.data.spCustomer,
      ShipNo: event.data.spAddress,
      PurchProduct: event.data.spProduct,
    };
    if (event.checked) {
      includePontentialMatch(exclusion);
    } else {
      excludePontentialMatch(exclusion);
    }
  };

  const isFiltered = Object.keys(matrixFilters).reduce(
    (acc, cur) => acc || matrixFilters[cur] !== null,
    false
  );

  return (
    <LoadingWrapper visible={loading}>
      <div className={classes.container}>
        <Grid container spacing={3}>
          <Grid className={classes.paperWrapper} item xs={12}>
            <Paper className={classes.paper}>
              <Box className={classes.header}>
                <Box display="flex" alignItems="center">
                  <h2>All Potential Matches {isFiltered && '(Filtered)'}</h2>
                  {hiddenFilter && (
                    <IconButton
                      size="small"
                      color="secondary"
                      onClick={() => hideFilter(false)}
                    >
                      <PenIcon />
                    </IconButton>
                  )}
                </Box>
              </Box>
              <Box className={hiddenFilter ? classes.hideFilter : null}>
                <MatrixFilter
                  filters={matrixFilters}
                  changeFilters={handleFiltersChange}
                  hide={() => hideFilter(true)}
                />
              </Box>
            </Paper>
          </Grid>
          <Grid className={classes.paperWrapper} item xs={12}>
            {matches && (
              <SortableTable
                rows={matches}
                headers={headCells}
                defaultRows={100}
                onToggleChange={handleExclusionChange}
              />
            )}
          </Grid>
        </Grid>
      </div>
    </LoadingWrapper>
  );
};

const mapStateToProps = (state) => ({
  loading: state.matrix.loading,
  matches: state.matrix.potentialMatches,
  pickupProducts: state.matrix.pickupProducts,
  shiptoProducts: state.matrix.shiptoProducts,
});

const mapDispatchToProps = (dispatch) => ({
  getPotentialMatches: (matrixFilters) => {
    return dispatch(getPotentialMatches(matrixFilters));
  },
  getPickupProducts: () => {
    dispatch(getPickupProducts());
  },
  getShiptoProducts: () => {
    dispatch(getShiptoProducts());
  },
  excludePontentialMatch: (match) => {
    dispatch(excludePontentialMatch(match));
  },
  includePontentialMatch: (match) => {
    dispatch(includePontentialMatch(match));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PotentialPage);
