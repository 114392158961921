import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import FilterField from '../../common/FilterField';
import DateRangePicker from '../../common/DateRangePicker';

import {
  typeOfLoads,
  pickUpLocations,
  shipToLocations,
} from '../../../utils/const';

const useStyles = makeStyles((theme) => ({
  box: {
    textAlign: 'right',
    margin: -theme.spacing(1),
    paddingTop: theme.spacing(1),
    borderTop: '1px solid #ccc',
  },
  filterField: {
    width: '25%',
  },
  filterBtn: {
    minWidth: 160,
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const MatrixFilter = ({
  filters,
  changeFilters,
  hide,
  products,
  scenarios,
  changeScenario,
  truckingCompanies
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <Box>
        <FilterField
          className={classes.filterField}
          label="Saved Scenario"
          labelKey="Name"
          choices={scenarios || []}
          value={filters.scenario}
          onChange={(e, value) => changeScenario(value)}
        />
        <FilterField
          className={classes.filterField}
          label="Product"
          choices={products || []}
          value={filters.product || []}
          disabled={filters.scenario}
          onChange={(e, value) => changeFilters('product', value)}
          multiple
        />
        <DateRangePicker
          className={classes.filterField}
          label="Pick Up Date"
          value={filters.pickUpDate}
          disabled={filters.scenario}
          onChange={(value) => changeFilters('pickUpDate', value)}
        />
        <DateRangePicker
          className={classes.filterField}
          label="Drop Off Date"
          value={filters.dropOffDate}
          disabled={filters.scenario}
          onChange={(value) => changeFilters('dropOffDate', value)}
        />
        <FilterField
          className={classes.filterField}
          label="Trucking Companies"
          choices={truckingCompanies || []}
          value={filters.truckingCompanies || []}
          onChange={(e, value) => changeFilters('truckingCompanies', value)}
          multiple
        />
        <FilterField
          className={classes.filterField}
          label="Type of Load"
          choices={typeOfLoads}
          value={filters.typeOfLoad || []}
          disabled={filters.scenario}
          onChange={(e, value) => changeFilters('typeOfLoad', value)}
          multiple
        />
        <FilterField
          className={classes.filterField}
          label="Pick Up Location"
          choices={pickUpLocations}
          value={filters.location}
          disabled={filters.scenario}
          onChange={(e, value) => changeFilters('location', value)}
        />
        <FilterField
          className={classes.filterField}
          label="Ship to City"
          choices={shipToLocations}
          value={filters.city}
          disabled={filters.scenario}
          onChange={(e, value) => changeFilters('city', value)}
        />
      </Box>
      <Button className={classes.filterBtn} color="secondary" onClick={hide}>
        Collapse Filters
      </Button>
    </Box>
  );
};

export default MatrixFilter;
