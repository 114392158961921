import axios from 'axios';
import SalesPrice from '../models/SalesPrice';
import { PricesByLocationParams, PricesOverTimeParams } from './types';
import { DEFAULT_HEADERS } from './Shared';

export async function findPricesByLocation(product: string, fromDate: Date, toDate: Date): Promise<Array<SalesPrice>> {
        const params: PricesByLocationParams = { product, fromDate, toDate }
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/pricesByLocation`, {  headers: DEFAULT_HEADERS, params });
        return response.data;
    } catch (err) {
        return [];
    }
}

export async function findPricesByProduct(customerNo: string,
    locationNo: string, product: string): Promise<Array<SalesPrice>> {
        const params: PricesOverTimeParams = { customerNo, locationNo, product }
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/pricesOverTime`, { headers: DEFAULT_HEADERS, params });
        return response.data;
    } catch (err) {
        return [];
    }
}