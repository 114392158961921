import { combineReducers } from 'redux';

import base from './base/reducer';
import c2s from './c2s/reducer';
import s2c from './s2c/reducer';
import matrix from './matrix/reducer';
import bids from './bids/reducer';

const root = combineReducers({
  base,
  c2s,
  s2c,
  matrix,
  bids,
});

export default root;
