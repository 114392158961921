import * as ActionTypes from './actionTypes';
import { findAllBids } from '../../repositories/BidsRepository';
import { findAllOffers } from '../../repositories/OffersRepository';
import { deleteOffer } from '../../repositories/OffersRepository';
import { deleteBid } from '../../repositories/BidsRepository';

export const loadBids = () => {
  return (dispatch) => {
    return findAllBids().then((bids) => {
      dispatch({
        type: ActionTypes.BID_LOAD_COMPLETE,
        payload: bids,
      });
    });
  };
};

export const loadOffers = () => {
  return (dispatch) => {
    return findAllOffers().then((offers) => {
      dispatch({
        type: ActionTypes.OFFER_LOAD_COMPLETE,
        payload: offers,
      });
    });
  };
};

export const removeOffer = (id) => {
  return (dispatch) => {
    return deleteOffer(id).then((success) => {
      if (success) {
        dispatch({
          type: ActionTypes.DELETE_OFFER_COMPLETE,
          payload: id,
        });
      }
    });
  };
};

export const removeBid = (id) => {
  return (dispatch) => {
    return deleteBid(id).then((success) => {
      if (success) {
        dispatch({
          type: ActionTypes.DELETE_BID_COMPLETE,
          payload: id,
        });
      }
    });
  };
};
