import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import PenIcon from '@material-ui/icons/CreateOutlined';
import { Redirect } from 'react-router-dom';

import MatrixFilter from './MatrixFilter';
import MatrixTable from './MatrixTable';
import FilterField from '../../common/FilterField';
import {
  getMatrixData,
  getMatrixProducts,
  getScenarios,
  getTruckingCompanies,
} from '../../../state/matrix/actions';
import {
  prepareMatchesForOptimizer,
  saveScenario,
  beginOptimizerRun,
  findScenario,
  findMatchesByScenario,
} from '../../../repositories/OptimizationRepository';
import LoadingWrapper from '../../common/LoadingWrapper';
import ScenarioModal from './ScenarioModal';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '90%',
    height: '100%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column'
  },
  paper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  paperWrapper: {
    marginTop: theme.spacing(3),
  },
  hideFilter: {
    height: 0,
    overflow: 'hidden',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    '& h2': {
      margin: 0,
    },
  },
  textCenter: {
    textAlign: 'center',
  },
  textError: {
    color: '#FC9B00',
  },
  rowsSelection: {
    width: 200
  }
}));

const MatrixScreen = ({
  getMatrixData,
  loading,
  matrixData,
  getMatrixProducts,
  matrixProducts,
  scenarios,
  getScenarios,
  truckingCompanies,
  getTruckingCompanies,
}) => {
  const classes = useStyles();
  const [matrixFilters, setMatrixFilters] = useState({
    scenario: null,
    product: null,
    dropOffDate: {
      startDate: new Date(),
      endDate: new Date(Date.now() + 12096e5),
    },
    pickUpDate: {
      startDate: new Date(),
      endDate: new Date(Date.now() + 12096e5),
    },
    typeOfLoad: null,
    location: null,
    city: null,
    tradeNo: null,
    truckingCompanies: null,
  });
  const [hiddenFilter, hideFilter] = useState(true);
  const [showScenarioModal, setShowScenarioModal] = useState(false);
  const [scenarioName, setScenarioName] = useState('');
  const [scenarioSaving, setScenarioSaving] = useState(false);
  const [excludedIds, setExcludedIds] = useState([]);
  const [matchedIds, setMatchedIds] = useState([]);
  const [status, setStatus] = useState('');
  const [done, setDone] = useState(false);
  const [rows, setRows] = useState(12);

  useEffect(() => {
    setStatus(null);
    const fetchData = async (filters) => {
      if (
        filters.typeOfLoad ||
        filters.product ||
        filters.location ||
        filters.city ||
        filters.truckingCompanies
      ) {
        await getMatrixData(filters);

        if (filters.scenario) {
          // TODO: await above
          setExcludedIds(
            filters.scenario.excludedIds?.map((id) => {
              return {
                col: matrixData.findColumnPosition(id.UPFID),
                row: matrixData.findRowPosition(id.UCFID),
              };
            })
          );

          findMatchesByScenario(filters.scenario.ID).then((matches) => {
            setMatchedIds(
              matches.map((id) => {
                return {
                  col: matrixData.findColumnPosition(id.UPFID),
                  row: matrixData.findRowPosition(id.UCFID),
                };
              })
            );
          });
        }
      }
    };

    fetchData(matrixFilters);
    // eslint-disable-next-line
  }, [matrixFilters]);

  useEffect(() => {
    const fetchData = async () => {
      !matrixProducts && getMatrixProducts();
      !scenarios && getScenarios();
      !truckingCompanies && getTruckingCompanies();
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  const handleConfirmLoad = () => {
    setStatus(null);
    setShowScenarioModal(true);
  };

  const handleLoadOptimizerData = async () => {
    const excludedTrades = excludedIds.map((cell) => {
      return matrixData.findHeadingByPosition(cell.row, cell.col);
    });
    try {
      setScenarioSaving(true);
      const scenarioId = await saveScenario(
        scenarioName,
        matrixFilters,
        excludedTrades
      );

      if (scenarioId <= 0) {
        setShowScenarioModal(false);
        setStatus('Unable to save scenario.');
        return;
      }

      const results = await prepareMatchesForOptimizer(
        matrixFilters,
        excludedTrades
      );
      if (!results) {
        setShowScenarioModal(false);
        setStatus('Unable to load data for optimizer.');
        return;
      }
      await beginOptimizerRun(scenarioId);
      setScenarioSaving(false);
      setDone(true); // redirect out of here
    } catch (err) {
      setStatus(err?.message);
      setScenarioName('');
      setShowScenarioModal(false);
      setScenarioSaving(false);
      return;
    }

    setScenarioName('');
    setShowScenarioModal(false);
  };

  const handleFiltersChange = (type, value) => {
    setMatrixFilters({
      ...matrixFilters,
      [type]: value,
    });
  };

  const handleScenarioChange = (value) => {
    setScenarioName(value);
  };

  const handleScenarioFilterChange = (value) => {
    if (!value) {
      // TODO: grab matched IDs from the latest
      setMatrixFilters({ ...matrixFilters, scenario: null });
      return;
    }
    // If scenario is chosen we need to set filters from database
    findScenario(value.id).then((scenario) => {
      // set filters
      setMatrixFilters({
        ...JSON.parse(scenario.FilterSet),
        scenario: { ID: scenario.ID, Name: scenario.Name },
      });
    });
  };

  const isFiltered = Object.keys(matrixFilters).reduce(
    (acc, cur) => acc || matrixFilters[cur] !== null,
    false
  );
  const noResults =
    matrixData &&
    (matrixData.columnHeadings.length === 0 ||
      matrixData.rowHeadings.length === 0);
  const rowsRange = Array.from(Array(31).keys()).slice(5);

  return done ? (
    <Redirect to="/matrixOptimize" />
  ) : (
    <LoadingWrapper visible={loading}>
      <div className={classes.container}>
        <div className={classes.paperWrapper}>
          <Paper className={classes.paper}>
            <Box className={classes.header}>
              <Box display="flex" alignItems="center">
                <h2>
                  {matrixFilters.scenario
                    ? matrixFilters.scenario.Name
                    : 'All Trades'}{' '}
                  {isFiltered && '(Filtered)'}
                </h2>
                {hiddenFilter && (
                  <IconButton
                    size="small"
                    color="secondary"
                    onClick={() => hideFilter(false)}
                  >
                    <PenIcon />
                  </IconButton>
                )}
              </Box>
              {status ? (
                <span className={classes.textError}>{status}</span>
              ) : null}
              <Button variant="contained" onClick={handleConfirmLoad}>
                Load Optimization
              </Button>
            </Box>
            <Box className={hiddenFilter ? classes.hideFilter : null}>
              <MatrixFilter
                filters={matrixFilters}
                products={matrixProducts}
                scenarios={scenarios}
                truckingCompanies={truckingCompanies}
                changeFilters={handleFiltersChange}
                changeScenario={handleScenarioFilterChange}
                hide={() => hideFilter(true)}
              />
            </Box>
          </Paper>
        </div>
        <div className={classes.paperWrapper} style={{flex: 1}}>
          {!isFiltered && (
            <h3 className={classes.textCenter}>
              Define your search criteria using the filters section above.
            </h3>
          )}
          {isFiltered &&
            !loading &&
            (noResults ? (
              <h3 className={classes.textCenter}>
                There are no results matching your search criteria.
              </h3>
            ) : (
              <>
                {matrixData && (
                  <FilterField
                    className={classes.rowsSelection}
                    label="Show"
                    labelFormat={(option) => `${option} rows`}
                    choices={rowsRange}
                    value={rows}
                    onChange={(e, value) => setRows(value)}
                  />
                )}
                <MatrixTable
                  rows={rows}
                  data={matrixData}
                  matchedIds={matchedIds}
                  excludedIds={excludedIds}
                  onExcludedIdsChange={setExcludedIds}
                />
              </>
            ))}
        </div>
        <ScenarioModal
          show={showScenarioModal}
          name={scenarioName}
          setName={handleScenarioChange}
          onSave={handleLoadOptimizerData}
          onCancel={() => setShowScenarioModal(false)}
          saving={scenarioSaving}
        />
      </div>
    </LoadingWrapper>
  );
};

const mapStateToProps = (state) => ({
  loading: state.matrix.loading,
  matrixData: state.matrix.matrixData,
  matrixProducts: state.matrix.matrixProducts,
  scenarios: state.matrix.scenarios,
  truckingCompanies: state.matrix.truckingCompanies,
});

const mapDispatchToProps = (dispatch) => ({
  getMatrixData: (matrixFilters) => {
    return dispatch(getMatrixData(matrixFilters));
  },

  getMatrixProducts: () => {
    return dispatch(getMatrixProducts());
  },

  getScenarios: () => {
    return dispatch(getScenarios());
  },

  getTruckingCompanies: () => {
    return dispatch(getTruckingCompanies());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MatrixScreen);
