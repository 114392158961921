import React from 'react';
import { connect } from 'react-redux';

import SortableTable from '../../common/SortableTable';

const headCells = [
  { id: 'Name', numeric: false, disablePadding: false, label: 'Location Name' },
  {
    id: 'cityState',
    numeric: true,
    disablePadding: false,
    label: 'City/State',
  },
  {
    id: 'lastTrade',
    numeric: false,
    disablePadding: false,
    label: 'Last Trade',
  },
  { id: 'Type', numeric: false, disablePadding: false, label: 'Location Type' },
  { id: 'Product', numeric: false, disablePadding: false, label: 'Product' },
  {
    id: 'prodVariant',
    numeric: false,
    disablePadding: false,
    label: 'Prod Variant',
  },
  {
    id: 'forecastTomorrow',
    numeric: true,
    disablePadding: false,
    label: 'Fcst Tomorrow',
  },
  {
    id: 'freightTomorrow',
    numeric: true,
    disablePadding: false,
    label: 'Freight Tomorrow',
  },
  {
    id: 'profitTomorrow',
    numeric: true,
    disablePadding: false,
    label: 'Profit Tomorrow',
  },
];

const PickupLocationsTable = ({ data, forecastDate }) => {
  const headers = forecastDate
    ? headCells.concat([
        {
          id: 'freightFuture',
          numeric: true,
          disablePadding: false,
          label: `Frght ${forecastDate.format('M/DD/YY')}`,
        },
        {
          id: 'forecastFuture',
          numeric: true,
          disablePadding: false,
          label: `Fcst ${forecastDate.format('M/DD/YY')}`,
        },
        {
          id: 'profitFuture',
          numeric: true,
          disablePadding: false,
          label: `Profit ${forecastDate.format('M/DD/YY')}`,
        },
      ])
    : headCells;
  let rows = []
  if (data) {
    rows = data.map(l => {
      if(l.freightTomorrow !== null) l.freightTomorrow = parseFloat(l.freightTomorrow).toFixed(0)
      if(l.profitTomorrow !== null) l.profitTomorrow = parseFloat(l.profitTomorrow).toFixed(0)
      if(l.forecastTomorrow !== null) l.forecastTomorrow = parseFloat(l.forecastTomorrow).toFixed(4)
      return l
    })
  }
  return <SortableTable headers={headers} rows={rows} />;
};

const mapStateToProps = (state) => ({
  data: state.c2s.locations,
  forecastDate: state.c2s.filters.date,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PickupLocationsTable);
