import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import promise from 'redux-loading-promise-middleware'
import isDev from 'isdev'

import reducers from './reducers'

const initialState = {}
const middleWares = [thunk, promise]

if (isDev) {
  middleWares.push(logger)
}

export default createStore(
  reducers,
  initialState,
  applyMiddleware(...middleWares)
)
