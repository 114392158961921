import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import FutureLoads from './FutureLoads';
import RecentBids from './RecentBids';
// import RecentActivity from './RecentActivity';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '90%',
    margin: '0 auto',
    flexGrow: 1,
  },
  gridItem: {
    marginTop: 30,
  },
}));

const DashboardScreen = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid className={classes.gridItem} item xs={12}>
          <RecentBids />
          <FutureLoads />
        </Grid>
      </Grid>
    </div>
  );
};

export default DashboardScreen;
