import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { AutoSizer, MultiGrid } from 'react-virtualized';
import Typography from '@material-ui/core/Typography';

import TooltipOverlay from './CellTooltipOverlay';
import HeaderTooltipOverlay from './HeaderTooltipOverlay';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%'
  },
  container: {
    maxHeight: 800,
  },
  headCell: {
    padding: 10,
    backgroundColor: '#F9FAFC',
    borderColor: '#EFF3F9',
    borderLeft: '1px solid',
    borderBottom: '1px solid #EFF3F9',
    boxSizing: 'border-box',
    cursor: 'pointer',
  },
  cell: {
    padding: 10,
    borderColor: '#EFF3F9',
    borderLeft: '1px solid',
    borderBottom: '1px solid #EFF3F9',
    cursor: 'pointer',
    position: 'relative',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: '#F9FAFC',
    },
  },
  excluded: {
    background:
      'linear-gradient(to top left, rgba(0,0,0,0) 0%, rgba(0,0,0,0) calc(50% - 1.5px), rgba(0,0,0,.5) 50%, rgba(0,0,0,0) calc(50% + 1.5px), rgba(0,0,0,0) 100%), linear-gradient(to top right, rgba(0,0,0,0) 0%, rgba(0,0,0,0) calc(50% - 1.5px), rgba(0,0,0,.5) 50%, rgba(0,0,0,0) calc(50% + 1.5px), rgba(0,0,0,0) 100%)',
    opacity: 0.5,
  },
  matched: {
    background: 'rgba(25,145,235,.25)',
  },
  check: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  bold: {
    fontWeight: 'bold',
  },
  tr: {
    '& td:first-child': {
      position: 'sticky',
    },
  },
  freight: {
    '& svg': {
      color: '#7C88A3',
    },
    '& p': {
      color: '#7C88A3',
    },
  },
  profit: {
    '& svg': {
      color: '#28BC36',
    },
    '& p': {
      color: '#28BC36',
    },
  },
  loss: {
    '& svg': {
      color: '#FB4546',
    },
    '& p': {
      color: '#FB4546',
    },
  },
  tooltip: {
    '& .rc-tooltip-arrow': {
      borderTopColor: '#101C2A',
    },
    '& .rc-tooltip-inner': {
      backgroundColor: '#101C2A',
      padding: '12px 20px',
    },
    flexContainer: {
      display: 'flex',
      alignItems: 'center',
      boxSizing: 'border-box',
    },
  },
});

const LoadTypes = [{
  values: ['NAV'],
  label: 'Real'
}, {
  values: ['vendor', 'pooled'],
  label: 'Forecasted'
}, {
  values: ['Bid'],
  label: 'Bid'
}, {
  values: ['Offer'],
  label: 'Offer'
}]

const getLoadType = (source) => {
  const selecteds = LoadTypes.filter(l => l.values.indexOf(source) > -1);
  return selecteds.length > 0 ? selecteds[0].label : '';
}

const MatrixTable = ({
  rows,
  data,
  excludedIds,
  onExcludedIdsChange,
  matchedIds,
}) => {
  const classes = useStyles();
  const [gridData, setGridData] = useState([]);
  const gridRef = useRef();

  useEffect(() => {
    if (data) {
      setGridData(
        data.cells.map((row, rowIndex) => {
          return row.map((cell, columnIndex) => {
            return {
              ...cell,
              isExcluded:
                excludedIds?.filter(
                  (id) => id.row === rowIndex && id.col === columnIndex
                ).length > 0,
              isMatched:
                matchedIds?.findIndex(
                  (id) => id.row === rowIndex && id.col === columnIndex
                ) > -1,
            };
          });
        })
      );
      gridRef.current.forceUpdateGrids();
    }
  }, [data, excludedIds, matchedIds]);

  const checkColumnOrRowExcluded = (row, col) => {
    let isAllCellsExculded = true;
    if (row > -1) { // checking if all cells in row are excluded
      for(let colId = 0; colId < data.columnHeadings.length; colId++) {
        const isCellExisted = data.cells[row][colId];
        if (isCellExisted) {
          const isIncluded =
            excludedIds.filter((id) => id.row === row && id.col === colId).length > 0;
          isAllCellsExculded = isAllCellsExculded && isIncluded;
        }
      }
      return isAllCellsExculded;
    } else if (col > -1) { // checking if all cells in column are excluded
      for(let rowId = 0; rowId < data.rowHeadings.length; rowId++) {
        const isCellExisted = data.cells[rowId][col];
        if (isCellExisted) {
          const isIncluded =
            excludedIds.filter((id) => id.row === rowId && id.col === col).length > 0;
          isAllCellsExculded = isAllCellsExculded && isIncluded;
        }
      }
      return isAllCellsExculded;
    }
  };

  const addOrRemoveToExcludes = (row, col) => {
    let ids = cloneDeep(excludedIds);
    const isExisted =
      ids.filter((id) => id.row === row && id.col === col).length > 0;
    if (isExisted) {
      ids = ids.filter((id) => id.row !== row || id.col !== col);
      onExcludedIdsChange(ids);
    } else {
      onExcludedIdsChange([...ids, { row, col }]);
    }
  };

  const addOrRemoveColumnAndRowToExcludes = (row, col) => {
    const isAllExcluded = checkColumnOrRowExcluded(row, col);
    let ids = cloneDeep(excludedIds);
    const allIds = [], idsToAdd = [];

    if (row < 0) { // getting ids of all cells in column
      let rowId = 0;
      while(rowId < data.rowHeadings.length) {
        allIds.push({row: rowId, col});
        rowId++;
      }
    } else if (col < 0) { // getting ids of all cells in row
      let colId = 0;
      while(colId < data.columnHeadings.length) {
        allIds.push({row, col: colId});
        colId++;
      }
    }

    if (isAllExcluded) {
      ids = ids.filter(id => {
        const isIncluded =
            allIds.filter((a) => a.row === id.row && a.col === id.col).length > 0;
        return !isIncluded;
      })
      onExcludedIdsChange([...ids]);
    } else {
      allIds.forEach(id => {
        const isExisted =
          ids.filter((id) => id.row === row && id.col === col).length > 0;
        if (!isExisted) {
          idsToAdd.push(id)
        }
      });
      onExcludedIdsChange([...ids, ...idsToAdd]);
    }
    
  };

  const cellRenderer = ({ columnIndex, key, rowIndex, style }) => {
    if (rowIndex === 0 && columnIndex === 0) {
      return <Box style={style}></Box>;
    }
    if (rowIndex === 0 && columnIndex > 0) {
      return displayHeader(data.columnHeadings[columnIndex - 1], 0, columnIndex, style);
    }
    if (columnIndex === 0 && rowIndex > 0) {
      return displayHeader(data.rowHeadings[rowIndex - 1], rowIndex, 0, style);
    }
    return displayCell(
      gridData[rowIndex - 1][columnIndex - 1],
      style,
      key,
      rowIndex,
      columnIndex
    );
  };

  const displayHeader = (headerData, rowIndex, columnIndex, style) => {
    const isAllExcluded = checkColumnOrRowExcluded(rowIndex - 1, columnIndex - 1);
    return (
      <Tooltip
        key={headerData.id}
        placement="topLeft"
        overlayClassName={classes.tooltip}
        overlay={
          <HeaderTooltipOverlay
            isExcluded={isAllExcluded}
            exclude={() =>
              addOrRemoveColumnAndRowToExcludes(rowIndex - 1, columnIndex - 1)
            }
          />
        }
      >
        <Box
          classes={{ root: `${classes.headCell} ${isAllExcluded && classes.excluded}` }}
          key={headerData.id}
          style={style}
        >
          <Typography
            noWrap
            classes={{ subtitle2: classes.bold }}
            variant="subtitle2"
          >
            {headerData.name}
          </Typography>
          <Typography noWrap variant="body2">
            {moment(headerData.date).format('MM/DD/YY')}
            {' '}
            {getLoadType(headerData.source)}
          </Typography>
          <Typography noWrap variant="body2">
            {headerData.product} {headerData.amount}
          </Typography>
        </Box>
      </Tooltip>
    );
  };

  const displayCell = (cellData, style, dataKey, rowIndex, columnIndex) => {
    if (cellData) {
      return (
        <Tooltip
          key={dataKey}
          placement="topLeft"
          overlayClassName={classes.tooltip}
          overlay={
            <TooltipOverlay
              data={cellData}
              isExcluded={cellData.isExcluded}
              exclude={() =>
                addOrRemoveToExcludes(rowIndex - 1, columnIndex - 1)
              }
            />
          }
        >
          <Box
            classes={{
              root: `${classes.cell} ${cellData.isExcluded && classes.excluded}
              ${cellData.isMatched && classes.matched}`,
            }}
            style={style}
          >
            <Box className={classes.freight} display="flex" alignItems="center">
              <ArrowForwardIcon fontSize="small" />
              <Typography noWrap variant="body1">
                {cellData.freightCost?.toFixed()}
              </Typography>
            </Box>
            <Box
              className={
                cellData.estimatedProfit > 0 ? classes.profit : classes.loss
              }
              display="flex"
              alignItems="center"
            >
              {cellData.estimatedProfit > 0 ? (
                <ArrowUpwardIcon fontSize="small" />
              ) : (
                <ArrowDownwardIcon fontSize="small" />
              )}
              <Typography noWrap variant="body1">
                {cellData.estimatedProfit?.toFixed()}
              </Typography>
            </Box>
            <Box className={classes.freight}>
              <Typography noWrap variant="body1">{cellData.trade}</Typography>
            </Box>
          </Box>
        </Tooltip>
      );
    }
    return (
      <Box className={classes.cell} key={dataKey} style={style}>
        {' '}
      </Box>
    );
  };

  return (
    <>
      {gridData && data && (
        <Paper className={classes.root}>
          <AutoSizer>
            {({ width, height }) => (
              <MultiGrid
                ref={(ref) => {
                  gridRef.current = ref;
                }}
                cellRenderer={cellRenderer}
                columnWidth={140}
                columnCount={data.columnHeadings.length}
                enableFixedColumnScroll
                enableFixedRowScroll
                height={(rows + 1) * 90}
                rowHeight={90}
                rowCount={data.rowHeadings.length}
                width={width}
                hideTopRightGridScrollbar
                hideBottomLeftGridScrollbar
                fixedColumnCount={1}
                fixedRowCount={1}
              />
            )}
          </AutoSizer>
        </Paper>
      )}
    </>
  );
};

export default MatrixTable;
