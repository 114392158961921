import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '33.3%',
    padding: theme.spacing(1),
    minWidth: 120,
    boxSizing: 'border-box',
  },
  legendText: {
    fontSize: 12,
    color: '#0c244280'
  },
  tooltip: {
    backgroundColor: '#101C2A',
    borderRadius: 4,
    fontSize: 12,
    color: 'white',
    padding: theme.spacing(1),
  }
}));

const CustomLineChart = (props) => {
  const classes = useStyles();

  const renderLegendText = (value, entry) => {
    return <span className={classes.legendText}>{value}</span>
  }

  const CustomTooltip = (tooltipProps) => {
    const { active } = tooltipProps
    if (active) {
      const { payload } = tooltipProps
      return (
        payload  &&
        <div className={classes.tooltip}>
          {payload[0] && `${payload[0].name} : $${payload[0].value}`}<br/>
          {payload[1] && `${payload[1].name} : $${payload[1].value}`}
        </div>
      );
    }

    return null;
  }

  return (
    <ResponsiveContainer height={240}>
      <LineChart
        data={props.data}
        margin={{
          top: 5, right: 5, left: 5, bottom: 5,
        }}
      >
        <CartesianGrid vertical={false} strokeDasharray="1" />
        <XAxis 
          tick={{fontSize: 12, fill: '#CDD0DA'}}
          tickLine={false}
          dataKey="date"
        />
        <YAxis
          tick={{fontSize: 12, fill: '#CDD0DA'}}
          tickLine={false}
          axisLine={false}
          tickFormatter={price => `$ ${price}`}
        />
        <Tooltip content={<CustomTooltip/>}/>
        <Legend verticalAlign="top" align="right" iconType="rect" height={36} formatter={renderLegendText} />
        <Line type="monotone" dataKey="Real" stroke="#3088F4" strokeWidth={2} dot={false} />
        <Line type="monotone" dataKey="Projected" stroke="#28BC36" strokeWidth={2} dot={false} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default CustomLineChart;
