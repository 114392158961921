import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import UsMap from '../../common/UsMap';
import SortableTable from '../../common/SortableTable';
import FilterField from '../../common/FilterField';
import DateSinglePicker from '../../common/DateSinglePicker';

import { findAllProducts } from '../../../repositories/LocationRepository';

import { findLocationsByProduct } from '../../../repositories/PickupLocationRepository';

import { findDotColor } from '../../../utils/functions';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '90%',
    margin: '0 auto',
  },
  paper: {
    padding: theme.spacing(2),
  },
  gridItem: {
    marginTop: 30,
  },
  header: {
    display: 'flex',
    borderBottom: '1px solid #ccc',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '& h2': {
      margin: 0,
    },
  },
  colorBar: {
    height: 8,
    width: 300,
    borderRadius: 4,
    background:
      'linear-gradient(74.19deg, #28BC35 0%, #FC9B00 49.6%, #FB4546 100%)',
  },
  colorLabel: {
    marginTop: -6,
    marginRight: 10,
  },
  mapContainer: {
    position: 'relative',
  },
}));

const headCells = [
  { id: 'Name', numeric: false, disablePadding: false, label: 'Location' },
  {
    id: 'lastPickupDate',
    numeric: false,
    disablePadding: false,
    label: 'Last Pick Up Date',
  },
  {
    id: 'lastTrade',
    numeric: false,
    disablePadding: false,
    label: 'Last Trade',
  },
  { id: 'Type', numeric: false, disablePadding: false, label: 'Location Type' },
  {
    id: 'lastUnitCost',
    numeric: true,
    disablePadding: false,
    label: 'Last Unit Cost',
  },
];

const LocationPickupScreen = (props) => {
  const classes = useStyles();
  const [date, setDate] = useState(null);
  const [locations, setLocations] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState('');

  useEffect(() => {
    fetchData(selectedProduct);
    const interval = setInterval(async () => {
      fetchData(selectedProduct);
    }, 30000);
    return () => clearInterval(interval);
  }, [selectedProduct]);

  const fetchData = async (productFilter) => {
    const locs = await findLocationsByProduct(productFilter);

    const minCost =
      locs &&
      locs.reduce((carry, loc) => {
        return carry == null || carry > loc.lastUnitCost
          ? loc.lastUnitCost
          : carry;
      }, null);
    const maxCost =
      locs &&
      locs.reduce((carry, loc) => {
        return carry < loc.lastUnitCost ? loc.lastUnitCost : carry;
      }, 0);

    // TODO: cache this (only select once?)
    findAllProducts().then((prods) => {
      if (prods) {
        setProductsList(prods.map((prod) => prod.Product));
      }
    });

    locs &&
      setMarkers(
        locs.map((loc) => {
          return {
            key: loc.Name,
            color: findDotColor(minCost, maxCost, loc.lastUnitCost),
            coordinates: [loc.Longitude, loc.Latitude],
            type: 'circle',
            data: {
              lastDate: loc.lastPickupDate,
              cityState: loc.cityState,
              lastTrade: loc.lastTrade,
            },
          };
        })
      );

    setLocations(locs);
  };

  return (
    <div className={classes.container}>
      <Grid container spacing={3}>
        <Grid className={classes.gridItem} item xs={12}>
          <Paper className={classes.paper}>
            <h2 className={classes.header}>Pick Up Locations</h2>
            <FilterField
              label="Purchase Product"
              onChange={(e, value) => setSelectedProduct(value)}
              choices={productsList}
              value={selectedProduct}
            />
            <DateSinglePicker
              value={date}
              onChange={(value) => setDate(value)}
            />
          </Paper>
        </Grid>
        <Grid className={classes.gridItem} item xs={12}>
          <Paper className={classes.paper}>
            <Box className={classes.header}>
              <h2>Pick Up Locations Map</h2>
              <Box display="flex" alignItems="flex-start">
                <Typography className={classes.colorLabel} variant="body2">
                  Forecasted Unit Cost
                </Typography>
                <Box display="flex" flexDirection="column">
                  <div className={classes.colorBar}>&nbsp;</div>
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="caption">Low</Typography>
                    <Typography variant="caption">Average</Typography>
                    <Typography variant="caption">High</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className={classes.mapContainer}>
              <UsMap type="pickup" markers={markers} />
            </Box>
          </Paper>
        </Grid>
        <Grid className={classes.gridItem} item xs={12}>
          <Paper className={classes.paper}>
            <SortableTable rows={locations} headers={headCells} />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default LocationPickupScreen;
