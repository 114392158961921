import React, { useState } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { withMsal } from '@azure/msal-react';
import AddIcon from '@material-ui/icons/AddCircleOutline';

import logo from '../../assets/images/WWS-Logo.png';
import AddBid from '../common/AddBid';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
  },
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
  root: {
  },
  menuButton: {
    marginLeft: theme.spacing(10),
  },
  logo: {
    width: 184,
  },
}));

const Navbar = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const [openAddBid, setOpenAddBid] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openedMenu, setOpenedMenu] = useState(null);

  const handleClick = (event, type) => {
    setAnchorEl(event.currentTarget);
    setOpenedMenu(type);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenedMenu(null);
  };

  const dashboardButtonProps =
    location.pathname === '/dashboard'
      ? { variant: 'contained', color: 'secondary' }
      : null;
  const locationButtonProps = location.pathname.startsWith('/location')
    ? { variant: 'contained', color: 'secondary' }
    : null;
  const tradingButtonProps = location.pathname.startsWith('/trading')
    ? { variant: 'contained', color: 'secondary' }
    : null;
  const matrixButtonProps = location.pathname.startsWith('/matrix')
    ? { variant: 'contained', color: 'secondary' }
    : null;

  if (location.pathname === '/') return null;

  const logout = () => {
    props.msalContext.instance.logout();
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar spacing={4}>
          <img className={classes.logo} src={logo} alt="Logo" />
          <Button
            className={classes.menuButton}
            color="inherit"
            {...dashboardButtonProps}
            component={RouterLink}
            to="/dashboard"
          >
            Dashboard
          </Button>

          <Button
            className={classes.menuButton}
            color="inherit"
            aria-controls="location-menu"
            aria-haspopup="true"
            onClick={(e) => handleClick(e, 'location')}
            {...locationButtonProps}
          >
            Locations <ExpandMore />{' '}
          </Button>
          <StyledMenu
            id="location-menu"
            anchorEl={anchorEl}
            keepMounted
            open={openedMenu === 'location' && Boolean(anchorEl)}
            onClose={handleClose}
          >
            <StyledMenuItem
              onClick={handleClose}
              component={RouterLink}
              to="/locationPickup"
            >
              Pick Up Locations
            </StyledMenuItem>
            <StyledMenuItem
              onClick={handleClose}
              component={RouterLink}
              to="/locationDropoff"
            >
              Ship To Locations
            </StyledMenuItem>
          </StyledMenu>

          <Button
            className={classes.menuButton}
            color="inherit"
            aria-controls="trading-menu"
            aria-haspopup="true"
            onClick={(e) => handleClick(e, 'trading')}
            {...tradingButtonProps}
          >
            Trading <ExpandMore />{' '}
          </Button>
          <StyledMenu
            id="trading-menu"
            anchorEl={anchorEl}
            keepMounted
            open={openedMenu === 'trading' && Boolean(anchorEl)}
            onClose={handleClose}
          >
            <StyledMenuItem
              onClick={handleClose}
              component={RouterLink}
              to="/tradingS2C"
            >
              Supplier to Customer
            </StyledMenuItem>
            <StyledMenuItem
              onClick={handleClose}
              component={RouterLink}
              to="/tradingC2S"
            >
              Customer to Supplier
            </StyledMenuItem>
          </StyledMenu>

          <Button
            className={classes.menuButton}
            color="inherit"
            aria-controls="matrix-menu"
            aria-haspopup="true"
            onClick={(e) => handleClick(e, 'matrix')}
            {...matrixButtonProps}
          >
            Matrix <ExpandMore />{' '}
          </Button>
          <StyledMenu
            id="matrix-menu"
            anchorEl={anchorEl}
            keepMounted
            open={openedMenu === 'matrix' && Boolean(anchorEl)}
            onClose={handleClose}
          >
            <StyledMenuItem
              onClick={handleClose}
              component={RouterLink}
              to="/matrix"
            >
              Matrix
            </StyledMenuItem>
            <StyledMenuItem
              onClick={handleClose}
              component={RouterLink}
              to="/matrixPotential"
            >
              Potential Matches
            </StyledMenuItem>
            <StyledMenuItem
              onClick={handleClose}
              component={RouterLink}
              to="/matrixOptimize"
            >
              Optimization Matches
            </StyledMenuItem>
          </StyledMenu>
          <Button
            className={classes.menuButton}
            variant="contained"
            color="secondary"
            startIcon={<AddIcon />}
            onClick={() => setOpenAddBid(true)}
          >
            Bid/Offer
          </Button>
          <AddBid open={openAddBid} handleClose={() => setOpenAddBid(false)} />
          <Button
            className={classes.menuButton}
            color="inherit"
            {...matrixButtonProps}
            onClick={logout}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default withMsal(Navbar);
