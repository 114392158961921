import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  btn: {
    width: 100,
    marginTop: theme.spacing(1),
  },
}));

const CellTooltipOverlay = ({ isExcluded, exclude }) => {
  const classes = useStyles();
  return (
    <Box>
      <Button
        className={classes.btn}
        color="secondary"
        variant="contained"
        size="small"
        onClick={exclude}
      >
        {isExcluded ? 'Include' : 'Exclude'}
      </Button>
    </Box>
  );
};

export default CellTooltipOverlay;
