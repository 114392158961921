import axios from 'axios';
import ShipToLocation from '../models/ShipToLocation';
import { DEFAULT_HEADERS } from './Shared';
import { PickupLocationParams } from './types';

export async function findAllLocations(product: string): Promise<Array<ShipToLocation>> {
  const url = `${process.env.REACT_APP_API_URL}/api/pickupLocations`
  let params: PickupLocationParams = {}
  if (product) {
    params.product = product
  }
  try {
    const response = await axios.get(url, { headers: DEFAULT_HEADERS, params });
    return response.data;
  } catch (err) {
    return [];
  }
}

export async function findAllProducts(): Promise<Array<String>> {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/pickupProducts`, { headers: DEFAULT_HEADERS });
    return response.data;
  } catch (err) {
    console.error(err)
    return [];
  }
}