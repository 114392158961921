import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  loaderContent: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, .6)',
  },
}));

const LoadingWrapper = ({visible, children}) => {
  const classes = useStyles();

  return (
    <>
      {children}
      {visible && (
        <Box className={classes.loaderContent} display="flex" justifyContent="center" alignItems="center">
          <CircularProgress color="secondary" size={50} thickness={4} />
        </Box>
      )}
    </>
  );
};

export default LoadingWrapper;
