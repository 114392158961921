import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const SelectElement = (props) => {
  const classes = useStyles();

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id="outlined-label">{props.label}</InputLabel>
      <Select
        labelId="outlined-label"
        id="select-outlined"
        value={props.value}
        onChange={props.onChange}
        label={props.label}
      >
        {
          props.options.map((item, index) => (
            <MenuItem key={index} value={item.value}>{item.label}</MenuItem>    
          ))
        }
      </Select>
    </FormControl>
  );
};

export default SelectElement;
