import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import FilterField from '../../common/FilterField';
import DateSinglePicker from '../../common/DateSinglePicker';

import {
  clearC2SData,
  changeC2SFilters,
  getC2SCities,
  getC2SProducts,
} from '../../../state/c2s/actions';

const useStyles = makeStyles((theme) => ({
  box: {
    textAlign: 'right',
    margin: -theme.spacing(1),
  },
  filterField: {
    width: '33%',
  },
  updateBtn: {
    minWidth: 160,
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const C2SFilter = ({
  filters,
  customers,
  cities,
  products,
  allLocationTypes,
  clearC2SData,
  setFilters,
  getC2SCities,
  getC2SProducts,
}) => {
  const classes = useStyles();
  useEffect(() => {
    clearC2SData({ cities: [], products: [] });
    setFilters('city', null);
    setFilters('product', null);
    if (filters.customer) {
      getC2SCities(filters.customer.customerNo);
      getC2SProducts(filters.customer.customerNo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.customer && filters.customer.customerNo]);

  return (
    <Box className={classes.box}>
      <Box>
        <FilterField
          label="Customer"
          labelKey="Name"
          choices={customers || []}
          value={filters.customer}
          onChange={(e, value) => setFilters('customer', value)}
        />
        <FilterField
          label="Ship To City"
          labelKey="cityState"
          choices={cities || []}
          value={filters.city}
          onChange={(e, value) => setFilters('city', value)}
        />
        <DateSinglePicker
          value={filters.date}
          onChange={(value) => setFilters('date', value)}
        />
        <FilterField
          className={classes.filterField}
          label="Ship To Product"
          choices={products || []}
          value={filters.product}
          onChange={(e, value) => setFilters('product', value)}
        />
        <FilterField
          className={classes.filterField}
          label="Pick Up Products"
          choices={products || []}
          value={filters.pickupProduct}
          onChange={(e, value) => setFilters('pickupProduct', value)}
        />
        <FilterField
          label="Potential Pick Up Location Type"
          choices={allLocationTypes || []}
          value={filters.locationType}
          onChange={(e, value) => setFilters('locationType', value)}
        />
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  filters: state.c2s.filters,
  customers: state.c2s.customers,
  products: state.c2s.products,
  cities: state.c2s.cities,
  allShipTypes: state.base.allShipTypes,
  allLocationTypes: state.base.allLocationTypes,
});

const mapDispatchToProps = (dispatch) => ({
  setFilters: (type, value) => {
    return dispatch(changeC2SFilters(type, value));
  },
  getC2SCities: (customer) => {
    return dispatch(getC2SCities(customer));
  },
  getC2SProducts: (customer) => {
    return dispatch(getC2SProducts(customer));
  },
  clearC2SData: (data) => {
    return dispatch(clearC2SData(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(C2SFilter);
