export const MATRIX_API_LOADING = 'MATRIX_API_LOADING';
export const MATRIX_API_FAILED = 'MATRIX_API_FAILED';

export const CLEAR_MATRIX_OPTIMIZED_MATCHES = 'CLEAR_MATRIX_OPTIMIZED_MATCHES';
export const GET_MATRIX_POTENTIAL_MATCHES_SUCCESS =
  'GET_MATRIX_POTENTIAL_MATCHES_SUCCESS';
export const GET_MATRIX_POTENTIAL_MATCH_PK_PRODS_SUCCESS =
  'GET_MATRIX_POTENTIAL_MATCH_PK_PRODS_SUCCESS';
export const GET_MATRIX_POTENTIAL_MATCH_SP_PRODS_SUCCESS =
  'GET_MATRIX_POTENTIAL_MATCH_SP_PRODS_SUCCESS';
export const GET_MATRIX_OPTIMIZED_MATCHES_SUCCESS =
  'GET_MATRIX_OPTIMIZED_MATCHES_SUCCESS';
export const GET_MATRIX_PRODS_SUCCESS = 'GET_MATRIX_PRODS_SUCCESS';
export const GET_MATRIX_DATA = 'GET_MATRIX_DATA';
export const GET_MATRIX_SCENARIOS = 'GET_MATRIX_SCENARIOS';
export const POTENTIAL_MATCH_EXCLUDED_SUCCESS =
  'POTENTIAL_MATCH_EXCLUDED_SUCCESS';
export const POTENTIAL_MATCH_INCLUDED_SUCCESS =
  'POTENTIAL_MATCH_INCLUDED_SUCCESS';
  export const GET_TRUCKING_COMPANIES_SUCCESS =
    'GET_TRUCKING_COMPANIES_SUCCESS';
