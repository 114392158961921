import axios from 'axios';
import MatrixData from '../models/MatrixData';
import MatchExclusion from '../models/MatchExclusion';
import { DEFAULT_HEADERS } from './Shared';
import { MatrixFilter, MatrixProduct } from './types';

export async function findMatrixData(matrixFilter: MatrixFilter): Promise<MatrixData> {
    try {
        const params = {
            ...matrixFilter,
            // product should either be a single product string or a list seperated by commas just like typeOfLoad
            product: Array.isArray(matrixFilter.product) ? matrixFilter.product.join(",") : matrixFilter.product,
            typeOfLoad: matrixFilter.typeOfLoad?.join(","),
            truckingCompanies: matrixFilter.truckingCompanies?.join(",")
        }
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/matrixOptions`, { headers: DEFAULT_HEADERS, params });
        
        let matrix = new MatrixData();
        await response.data.forEach((element: any) => {
            const row = {id: element.UCFID, name: element.PickCityState,
                product: element.PurchItem,
                date: element.PickupDate,
                amount: element.CalculatedCost,
                source: element.CostSource,
            };
            const col = {id: element.UPFID, name: element.ShipCityState,
                product: element.SalesItem,
                date: element.DeliveryDate,
                amount: element.CalculatedPrice,
                source: element.PriceSource,
            };
            const cell = {
                estimatedProfit: element.EstProfitMean,
                freightCost: element.ForecastFreightCost,
                unitPrice: element.CalculatedPrice,
                weight: 0,
                freightCompany: "Unknown" ,
                // If trade headers are equal we know this cell is the one that was "matched" into this trade.
                trade: element.UPTrade && element.UPTrade === element.UCTrade ? element.UPTrade : null,
                isExcluded: false,
                TruckingCompany: element.TruckingCompany,
            };
            matrix.addMatrixCell(cell, row, col);
        });
        return matrix;
    } catch (err) {
        console.log('ERROR', err)
        return new MatrixData();
    }
}

export async function findProducts(): Promise<Array<String>> {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/matrixProducts`, { headers: DEFAULT_HEADERS });
        return response.data.map((prod: MatrixProduct) => prod.PurchItem);
    } catch (err) {
        console.log('ERROR', err)
        return []
    }
}


export async function findTruckingCompanies(): Promise<Array<String>> {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/truckingCompanies`, { headers: DEFAULT_HEADERS });
        return response.data;
    } catch (err) {
        console.log('ERROR', err)
        return []
    }
}

export async function addMatchExclusion(exclusion: MatchExclusion): Promise<boolean> {
    try {
        const instance = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 1000,
            headers: DEFAULT_HEADERS
          });
        const response = await instance.post('/api/createExclusion', exclusion);
        return response.data.id !== undefined 
    } catch (err) {
        return false;
    }
}

export async function deleteMatchExclusion(exclusion: MatchExclusion): Promise<boolean> {
    try {
        const instance = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 1000,
            headers: DEFAULT_HEADERS
          });
        const response = await instance.post('/api/deleteExclusion', exclusion);
        return response.data.id !== undefined 
    } catch (err) {
        return false;
    }
}