import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import DashboardPage from './pages/dashboard/DashboardPage';
import LoginPage from './authentication/LoginScreen';
import LocationPickupPage from './pages/locationPickup/LocationPickupPage';
import LocationDropoffPage from './pages/locationDropoff/LocationDropoffPage';
import MatrixPage from './pages/matrix/MatrixPage';
import MatrixPotentialPage from './pages/matrixPotential/PotentialPage';
import MatrixOptimizePage from './pages/matrixOptimize/OptimizePage';
import TradingC2SPage from './pages/tradingC2S/TradingC2SPage';
import TradingS2CPage from './pages/tradingS2C/TradingS2CPage';
import { useIsAuthenticated } from '@azure/msal-react';

const Navigation = () => {
  const isAuthenticated = useIsAuthenticated();
  return isAuthenticated ? (
    <>
      <Route exact path="/">
        <Redirect to="/dashboard" />
      </Route>
      <Route exact path="/dashboard" component={DashboardPage} />
      <Route path="/matrix" component={MatrixPage} />
      <Route path="/matrixPotential" component={MatrixPotentialPage} />
      <Route path="/matrixOptimize" component={MatrixOptimizePage} />
      <Route path="/locationPickup" component={LocationPickupPage} />
      <Route path="/locationDropoff" component={LocationDropoffPage} />
      <Route path="/tradingC2S" component={TradingC2SPage} />
      <Route path="/tradingS2C" component={TradingS2CPage} />
    </>
  ) : (
    <LoginPage />
  );
};

export default Navigation;
