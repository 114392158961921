import ShipToLocation from '../models/ShipToLocation';
import MapMarker from './MapMarker';
import Coordinates from './Coordinates';
import MapLine from './MapLine';

export function locationsToMapMarkers(
  locations: Array<ShipToLocation>
): Array<MapMarker> {
  return locations.map((location) => {
    return {
      key: location.locationNo,
      color: '#111111',
      coordinates: [location.Longitude, location.Latitude],
      type: 'circle',
      lastTrade: location.lastTrade,
      lastDeliveryDate: location.lastDeliveryDate,
      customerNo: location.customerNo
    };
  });
}

export function locationsToMapLines(
  startCoordinates: Coordinates,
  locations: Array<ShipToLocation>
): Array<MapLine> {
  return locations.map((location) => {
    return {
      key: location.locationNo,
      from: [startCoordinates.lon, startCoordinates.lat],
      to: [location.Longitude, location.Latitude],
      customerNo: location.customerNo
    };
  });
}

export function calculateProfitForLocations(
  locations: Array<ShipToLocation>
): Array<ShipToLocation> {
  return locations.map((location) => {
    if (!location.freightCost || location.freightCost <= 0) {
      return location;
    }
    return {
      ...location,
      forecastedProfitTomorrow: location.forecastTomorrow 
    }
  });
}
