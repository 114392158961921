import MatrixHeading from './MatrixHeading';
import MatrixCell from './MatrixCell';

export default class MatrixData {
    rowHeadings: Array<MatrixHeading> = [];
    columnHeadings: Array<MatrixHeading> = [];
    cells: Array<Array<MatrixCell>> = [[]];

    /**
     * 
     * @param heading 
     * @returns position of added heading
     */
    addColumnHeading(heading: MatrixHeading) {
        const position = this.findColumnPosition(heading.id);
        if (position < 0) {
            return this.columnHeadings.push(heading) - 1;
        }
        return position;
        
    }

    findColumnPosition(headingId: string) {
        return this.columnHeadings.findIndex((col) => {
            return col.id === headingId;
        });
    }

    /**
     * 
     * @param heading 
     * @returns position of added heading
     */
    addRowHeading(heading: MatrixHeading) {
        const position = this.findRowPosition(heading.id);
        if (position < 0) {
            return this.rowHeadings.push(heading) - 1;
        }
        return position;
    }

    findRowPosition(headingId: string) {
        return this.rowHeadings.findIndex((row) => {
            return row.id === headingId;
        });
    }

    addMatrixCell(cell: MatrixCell, row: MatrixHeading, col: MatrixHeading) {
        const colNum = this.addColumnHeading(col);
        const rowNum = this.addRowHeading(row);
        if (!this.cells[rowNum]) {
            this.cells[rowNum] = [];
        }
        this.cells[rowNum][colNum] = cell;
    }

    findHeadingByPosition(row: number, col: number) {
        return [
            this.rowHeadings[row].id,
            this.columnHeadings[row].id
        ]
    }
}