import axios from 'axios';
import OptimizerScenario from '../models/OptimizerScenario';
import PotentialMatch from '../models/PotentialMatch';
import ChosenMatch from '../models/ChosenMatch';
import { DEFAULT_HEADERS } from './Shared';
import { MatrixFilter, MatchFilter, MatrixPotentialFilter } from './types';

export async function findPotentialMatches(matrixFilter: MatrixPotentialFilter): Promise<Array<PotentialMatch>> {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/potentialMatches`, { headers: DEFAULT_HEADERS, params: matrixFilter });
        if (matrixFilter.showExcluded) {
            return response.data.filter((d: PotentialMatch) => d.excluded === 0)
        }
        return response.data;
    } catch (err) {
        return [];
    }
}

export async function findPotentialMatchPickupProducts(): Promise<Array<String>> {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/potentialMatchPickupProducts`, { headers: DEFAULT_HEADERS });
        return response.data && response.data.map((p: any) => { return p.pkProduct });
    } catch (err) {
        return [];
    }
}

export async function findPotentialMatchShiptoProducts(): Promise<Array<String>> {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/potentialMatchShiptoProducts`, { headers: DEFAULT_HEADERS });
        return response.data && response.data.map((p: any) => { return p.spProduct });
    } catch (err) {
        return [];
    }
}

export async function prepareMatchesForOptimizer(matrixFilter: MatrixFilter, excludedIds: Array<string>): Promise<Boolean> {
    try {
        const params = {...matrixFilter, excludedIds}
        await axios.get(`${process.env.REACT_APP_API_URL}/api/prepareOptimizerData`, { headers: DEFAULT_HEADERS, params });
        return true;
    } catch (err) {
        return false;
    }
}

export async function findOptimizedMatches(filters: MatchFilter): Promise<Array<ChosenMatch>> {
    try {
        const params = {...filters, scenario: filters.scenario.id}
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/findOptimizedMatches`, { headers: DEFAULT_HEADERS, params });
        return response.data;
    } catch (err) {
        return [];
    }
}

export async function findMatchesByScenario(scenarioId: number): Promise<Array<ChosenMatch>> {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/findOptimizedMatches`, { headers: DEFAULT_HEADERS, params: {scenario: scenarioId} });
    return response.data;
}

export async function findScenarios(): Promise<Array<OptimizerScenario>> {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/findScenarios`, { headers: DEFAULT_HEADERS });
        return response.data;
    } catch (err) {
        return [];
    }
}

export async function findScenario(id: number): Promise<OptimizerScenario> {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/findScenarioById`, { headers: DEFAULT_HEADERS, params: {id} });
        return response.data;
    } catch (err) {
        return {id: -1};
    }
}

export async function saveScenario(name: String, filters: MatrixFilter, excludedIds: Array<Array<string>>): Promise<number> {
    try {
        const instance = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 1000,
            headers: DEFAULT_HEADERS
          });
        // scenario filter is not needed since it is tied to the scenario we are saving  
        const { scenario, ...actualFilters} = filters;  
        const response = await instance.post(`/api/saveScenario`, {name, filters: JSON.stringify(actualFilters), excludedIds});
        return response.data.id;
    } catch (err) {
        if (err.response) {
            throw err.response.data.error;        
        }
        return -1;
    }
}

export async function beginOptimizerRun(scenarioId: number): Promise<Boolean> {
    try {
        const response = await axios.get('https://ficoxpress.wwstrading.com/optimize', {
            headers: {'Authorization': 'Bearer UpvySQhu6T9ND5xl2MKDRwNqauobaoRqr1VRIhUYxh31JSgA'},
            params: {scenarioId}
        });
        console.log(response);
        return response.data;
    } catch (err) {
        console.log(err);
        return false;
    }
}