import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}));

const RoundedButton = (props) => {
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      color="secondary"
      {...props}
      className={`${classes.root} ${props.className}`}
    >
      {props.children}
    </Button>
  );
};

export default RoundedButton;
