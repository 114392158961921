import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import CircleIcon from '@material-ui/icons/FiberManualRecord';
import TriangleIcon from '@material-ui/icons/ChangeHistory';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import UsMap from '../../common/UsMap';
import SortableTable from '../../common/SortableTable';
import FilterField from '../../common/FilterField';
import DateSinglePicker from '../../common/DateSinglePicker';

import { findDotColor } from '../../../utils/functions';

import { findAllProducts } from '../../../repositories/LocationRepository';

import { findLocationsByProduct } from '../../../repositories/ShipToLocationRepository';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '90%',
    margin: '0 auto',
  },
  paper: {
    padding: theme.spacing(2),
  },
  gridItem: {
    marginTop: 30,
    textAlign: 'right',
  },
  updateBtn: {
    minWidth: 160,
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  header: {
    display: 'flex',
    borderBottom: '1px solid #ccc',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '& h2': {
      margin: 0,
    },
  },
  colorBar: {
    height: 8,
    width: 300,
    borderRadius: 4,
    background:
      'linear-gradient(74.19deg, #28BC35 0%, #FC9B00 49.6%, #FB4546 100%)',
  },
  colorLabel: {
    marginTop: -6,
    marginRight: 10,
  },
  mapContainer: {
    position: 'relative',
  },
  mapPane: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: 0,
    right: 0,
    padding: theme.spacing(1),
    '& label': {
      margin: 0,
      '& span': {
        padding: 1,
      },
    },
  },
  icon: {
    width: 13,
    height: 13,
    verticalAlign: 'middle',
  },
}));

const headCells = [
  { id: 'Name', numeric: false, disablePadding: false, label: 'Location' },
  {
    id: 'lastTrade',
    numeric: false,
    disablePadding: false,
    label: 'Last Trade',
  },
  { id: 'Type', numeric: false, disablePadding: false, label: 'Location Type' },
  {
    id: 'lastUnitPrice',
    numeric: true,
    disablePadding: false,
    label: 'Last Unit Price',
  },
];

const ShipToLocationScreen = (props) => {
  const classes = useStyles();
  const [locations, setLocations] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  // eslint-disable-next-line
  const [descriptionsList, setDescriptionsList] = useState([]);
  const [selectedDescription, setSelectedDescription] = useState(null);
  const [date, setDate] = useState(null);
  const [customerFilter, setCustomerFilter] = useState({
    returning: true,
    new: true,
  });

  useEffect(() => {
    findAllProducts().then((prods) => {
      if (prods) {
        setProductsList(prods.map((prod) => prod.Product));
      }
    });
  }, []);

  useEffect(() => {
    fetchData(selectedProduct);
    // const interval = setInterval(async () => {
    //   fetchData(selectedProduct);
    // }, 30000);
    // return () => clearInterval(interval);
  }, [selectedProduct]);

  const fetchData = async (productFilter) => {
    const locs = await findLocationsByProduct(productFilter);

    const minCost = locs
      ? locs.reduce((carry, loc) => {
          return carry == null || carry > loc.lastUnitPrice
            ? loc.lastUnitPrice
            : carry;
        }, null)
      : 0;
    const maxCost = locs
      ? locs.reduce((carry, loc) => {
          return carry < loc.lastUnitPrice ? loc.lastUnitPrice : carry;
        }, 0)
      : 0;

    setMarkers(
      locs
        .filter((loc) => {
          return loc.Latitude && loc.Longitude;
        })
        .map((loc, index) => {
          return {
            key: loc.Name,
            color: findDotColor(minCost, maxCost, loc.lastUnitPrice),
            coordinates: [loc.Longitude, loc.Latitude],
            type: index % 3 === 0 ? 'triangle' : 'circle',
            data: {
              lastDate: loc.lastDeliveryDate,
              cityState: loc.cityState,
              lastTrade: loc.lastTrade,
            },
          };
        })
    );

    setLocations(locs);
  };

  const handleChangeCheckbox = (event) => {
    setCustomerFilter({
      ...customerFilter,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <div className={classes.container}>
      <Grid container spacing={3}>
        <Grid className={classes.gridItem} item xs={12}>
          <Paper className={classes.paper}>
            <div className={classes.header}>
              <h2>Ship To Locations</h2>
            </div>
            <FilterField
              label="Product"
              onChange={(e, value) => setSelectedProduct(value)}
              choices={productsList}
              value={selectedProduct}
            />
            <FilterField
              label="Product/Item Description"
              onChange={(e, value) => setSelectedDescription(value)}
              choices={descriptionsList}
              value={selectedDescription}
            />
            <DateSinglePicker
              value={date}
              onChange={(value) => setDate(value)}
            />
            <Button
              className={classes.updateBtn}
              variant="contained"
              color="secondary"
            >
              Filter
            </Button>
          </Paper>
        </Grid>
        <Grid className={classes.gridItem} item xs={12}>
          <Paper className={classes.paper}>
            <Box className={classes.header}>
              <h2>Ship To Locations Map</h2>
              <Box display="flex" alignItems="flex-start">
                <Typography className={classes.colorLabel} variant="body2">
                  Forecasted Unit Cost
                </Typography>
                <Box display="flex" flexDirection="column">
                  <div className={classes.colorBar}>&nbsp;</div>
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="caption">Low</Typography>
                    <Typography variant="caption">Average</Typography>
                    <Typography variant="caption">High</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className={classes.mapContainer}>
              <UsMap type="dropoff" markers={markers} />
              <Paper className={classes.mapPane}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={customerFilter.returning}
                      onChange={handleChangeCheckbox}
                      name="returning"
                      color="secondary"
                      size="small"
                    />
                  }
                  label={
                    <Typography variant="body2">
                      Returning Customer (
                      <CircleIcon className={classes.icon} />)
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={customerFilter.new}
                      onChange={handleChangeCheckbox}
                      name="new"
                      color="secondary"
                      size="small"
                    />
                  }
                  label={
                    <Typography variant="body2">
                      New Customer (<TriangleIcon className={classes.icon} />)
                    </Typography>
                  }
                />
              </Paper>
            </Box>
          </Paper>
        </Grid>
        <Grid className={classes.gridItem} item xs={12}>
          <Paper className={classes.paper}>
            <div className={classes.header}>
              <h2>Ship To Location List</h2>
              <Button color="secondary" startIcon={<AddIcon />}>
                Add New Bid
              </Button>
            </div>
            <SortableTable rows={locations} headers={headCells} />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default ShipToLocationScreen;
