import _ from 'lodash';
import * as ActionTypes from './actionTypes';

const initialState = {
  loading: false,
  locations: null,
  markers: null,
  lines: null,
  customers: null,
  products: null,
  cities: null,
  filters: {
    product: null,
    customer: null,
    city: null,
    shipType: null,
    locationType: null,
    date: null,
  },
  costsChartData: null,
  pricesChartData: null,
};

const reducer = (state = initialState, action) => {
  let newState = _.cloneDeep(state);

  switch (action.type) {
    case ActionTypes.C2S_API_LOADING:
      newState.loading = true;
      return newState;

    case ActionTypes.C2S_API_FAILED:
      newState.loading = false;
      return newState;

    case ActionTypes.CHANGE_C2S_FILTERS: {
      const newFilters = _.cloneDeep(newState.filters);
      newFilters[action.payload.type] = action.payload.value;
      newState.filters = newFilters;
      return newState;
    }

    case ActionTypes.CLEAR_C2S_DATA:
      return {
        ...newState,
        ...action.payload,
      };

    case ActionTypes.GET_C2S_LOCATIONS_SUCCESS:
      newState.loading = false;
      newState.locations = action.payload.locations;
      newState.markers = action.payload.markers;
      newState.lines = action.payload.lines;
      return newState;

    case ActionTypes.GET_C2S_CUSTOMERS_SUCCESS:
      newState.loading = false;
      newState.customers = action.payload;
      return newState;

    case ActionTypes.GET_C2S_PRODUCTS_SUCCESS:
      newState.loading = false;
      newState.products = action.payload;
      if (action.payload && action.payload.length === 1) {
        newState.filters.product = action.payload[0]
      }
      return newState;

    case ActionTypes.GET_C2S_CITIES_SUCCESS: {
      newState.loading = false;
      newState.cities = action.payload;
      if (action.payload.length === 1) {
        newState.filters.city = action.payload[0];
      }
      return newState;
    }

    case ActionTypes.GET_C2S_COSTS_CHART_DATA:
      newState.loading = false;
      newState.costsChartData = action.payload;
      return newState;

    case ActionTypes.GET_C2S_PRICES_CHART_DATA:
      newState.loading = false;
      newState.pricesChartData = action.payload;
      return newState;

    default:
      return state;
  }
};

export default reducer;
