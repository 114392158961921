import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';

import SortableTable from '../../common/SortableTable';
import RecentOffersTable from './RecentOffersTable';
import { loadBids, removeBid } from '../../../state/bids/actions';

const headCells = [
  { id: 'Customer', numeric: false, disablePadding: false, label: 'Customer' },
  { id: 'DeliveryCity', numeric: false, disablePadding: false, label: 'City' },
  {
    id: 'DeliveryDate',
    numeric: false,
    disablePadding: false,
    label: 'Delivery Date',
  },
  { id: 'Product', numeric: false, disablePadding: false, label: 'Product' },
  { id: 'Quantity', numeric: true, disablePadding: false, label: 'Quantity' },
  { id: 'Price', numeric: true, disablePadding: false, label: 'Price' },
  { id: 'PriceTerms', numeric: false, disablePadding: false, label: 'Terms' },
  { id: 'Delete', numeric: false, disablePadding: false, label: '' },
];

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginBottom: theme.spacing(3),
  },
  description: {
    color: theme.palette.text.secondary,
  },
  list: {
    display: 'flex',
    alignSelf: 'baseline',
    border: '1px solid #1991EB',
    padding: 0,
  },
  item: {
    backgroundColor: 'unset',
    borderLeft: '1px solid #1991EB',
    paddingTop: 0,
    paddingBottom: 0,
    color: '#1991EB',
    '&:first-child': {
      borderLeft: 'unset',
    },
  },
  activeItem: {
    backgroundColor: '#1991EB !important',
    color: 'white',
  },
  itemText: {
    fontSize: 11,
    whiteSpace: 'nowrap',
  },
}));

const RecentBids = ({ loadBids, bids, removeBid }) => {
  const classes = useStyles();
  // const [selectedIndex, setSelectedIndex] = useState(1);

  useEffect(() => {
    loadBids();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = (index) => {
    removeBid(bids[index].ID);
  };

  return (
    <Paper className={classes.paper}>
      <div className={classes.top}>
        <div>
          <h2>Recent Bid/Offers</h2>
          <span className={classes.description}>
            Recent bids for quick reference
          </span>
        </div>
      </div>
      {bids && (
        <SortableTable
          headers={headCells}
          rows={bids}
          deletable={true}
          onDelete={handleDelete}
        />
      )}
      <RecentOffersTable />
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  bids: state.bids.bids,
});

const mapDispatchToProps = (dispatch) => ({
  loadBids: () => {
    return dispatch(loadBids());
  },
  removeBid: (id) => {
    return dispatch(removeBid(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RecentBids);
