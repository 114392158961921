import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  positiveRow: {
    color: theme.palette.success.main,
    textDecoration: 'underline',
  },
  negativeRow: {
    color: theme.palette.error.main,
    textDecoration: 'underline',
  },
  title: {
    fontSize: 16
  }
}));

const formatter = Intl.NumberFormat('en-US')

const FutureLoadDetails = props => {
  const classes = useStyles();

  return (
    <div>
      {props.data ?
          <Table className={classes.table} aria-label="spanning table">
          <TableHead>
            <TableRow>
            <TableCell colSpan={8} className={classes.title}>{props.title}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          <TableRow key='purchased'>
          <TableCell>Purchased Wt</TableCell>
          {props.data.map((detail) => (
            <TableCell key={detail.date + '_purchased'}>{detail.purchased}</TableCell>
          ))}
          </TableRow>
          <TableRow key='sold'>
          <TableCell>Sold Wt</TableCell>
          {props.data.map((detail) => (
            <TableCell key={detail.date + '_sold'}>{detail.sold}</TableCell>
          ))}
          </TableRow>
          <TableRow key='difference'>
          <TableCell>Difference</TableCell>
          {props.data.map((detail) => (
            <TableCell key={detail.date + '_diff'} className={detail.purchased - detail.sold > 0 ? classes.positiveRow : classes.negativeRow}>{formatter.format( detail.purchased - detail.sold ) }</TableCell>
          ))}
          </TableRow>

        </TableBody>
      </Table>
       : 
    <Typography>No data found</Typography> }
    </div>
  );
}

export default FutureLoadDetails;