export const typeList = ['Type 1', 'Type 2', 'Type 3']

export const chartData1 = [
  { date: 'Jan 2020', Real: 0.24 },
  { date: 'Feb 2020', Real: 0.14 },
  { date: 'Mar 2020', Real: 0.98 },
  { date: 'Apr 2020', Real: 0.39 },
  { date: 'May 2020', Real: 0.48 },
  { date: 'Jun 2020', Real: 0.38 },
  { date: 'Jul 2020', Real: 0.43 },
];

export const chartData2 = [
  { date: 'Jan 2020', Projected: 0.4, Real: 0.24 },
  { date: 'Feb 2020', Projected: 0.3, Real: 0.14 },
  { date: 'Mar 2020', Projected: 0.2, Real: 0.98 },
  { date: 'Apr 2020', Projected: 0.27, Real: 0.39 },
  { date: 'May 2020', Projected: 0.18, Real: 0.48 },
  { date: 'Jun 2020', Projected: 0.24, Real: 0.38 },
  { date: 'Jul 2020', Projected: 0.35, Real: 0.43 },
];

export const chartViewOptions = [
  {value: 'day', label: 'Day'},
  {value: 'week', label: 'Week'},
  {value: 'month', label: 'Month'},
];
