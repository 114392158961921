import React, {useState, useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import UsMap from '../../common/UsMap';
import { getDynamicHexColorFromText } from '../../../utils/functions'

const useStyles = makeStyles((theme) => ({
  codeBox: {
    width: '100%',
    display: 'flex',
    overflow: 'auto',
    paddingBottom: theme.spacing(1)
  },
  codeItem: {
    display: 'flex',
    alignItems: 'center',
    color: '#0c244280',
    fontSize: 12.65,
    padding: '4px 8px',
    textTransform: 'uppercase',
  },
  colorCircle: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    borderRadius: '50%',
    border: '1px solid #0c244280',
    marginRight: theme.spacing(.5)
  },
}));

const ShipToLocationsMap = (props) => {
  const classes = useStyles();
  const [colors, setColors] = useState({});
  useEffect(() => {
    let newColors = {}
    props.markers && props.markers.length > 0 && props.markers.forEach((marker) => {
      newColors[marker.key] = getDynamicHexColorFromText(marker.key || '')
    });
    setColors(newColors);
  }, [props.markers]);

  return (
    <div>
      <div className={classes.codeBox}>
        {
          Object.keys(colors).map((key, index) => (
            <div key={index} className={classes.codeItem}>
              <div className={classes.colorCircle} style={{backgroundColor: colors[key]}} />
              {`${key}`}
            </div>
          ))
        }
      </div>
      <UsMap type={props.type} height={500} colors={colors} markers={props.markers} lines={props.lines} />
    </div>
  );
};

export default ShipToLocationsMap;
