export const C2S_API_LOADING = 'C2S_API_LOADING';
export const C2S_API_FAILED = 'C2S_API_FAILED';

export const CHANGE_C2S_FILTERS = 'CHANGE_C2S_FILTERS';
export const CLEAR_C2S_DATA = 'CLEAR_C2S_DATA';

export const GET_C2S_LOCATIONS_SUCCESS = 'GET_C2S_LOCATIONS_SUCCESS';
export const GET_C2S_CUSTOMERS_SUCCESS = 'GET_C2S_CUSTOMERS_SUCCESS';
export const GET_C2S_PRODUCTS_SUCCESS = 'GET_C2S_PRODUCTS_SUCCESS';
export const GET_C2S_CITIES_SUCCESS = 'GET_C2S_CITIES_SUCCESS';

export const GET_C2S_COSTS_CHART_DATA = 'GET_C2S_COSTS_CHART_DATA';
export const GET_C2S_PRICES_CHART_DATA = 'GET_C2S_PRICES_CHART_DATA';
