import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
} from '@material-ui/core/styles';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';

import Navigation from './components/Navigation';
import Navbar from './components/layouts/navbar';

import store from './state/store';
import './App.css';

/**
 * Azure AD config
 */

const pca = new PublicClientApplication({
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
    authority:
      'https://login.microsoftonline.com/e85f2439-0384-47b3-86e7-4a6414c9a98c/',
    redirectUri: process.env.REACT_APP_AZURE_REDIRECT_URL,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#101C2A',
    },
    secondary: {
      main: '#1991EB',
    },
    error: {
      main: '#FB4546',
    },
    warning: {
      main: '#FC9B00',
    },
    success: {
      main: '#28BC36',
    },
    text: {
      secondary: '#939393',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 20,
      },
    },
  },
  typography: {
    fontFamily: "'Source Sans Pro', sans-serif",
    button: {
      textTransform: 'none',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  app: {
    // width: '100vw',
    height: '100vh',
    // overflow: 'auto',
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: 1,
  }
}));

function App() {
  const classes = useStyles();

  return (
    <MsalProvider instance={pca}>
      <Provider store={store}>
        <BrowserRouter>
          <div className={classes.app}>
            <ThemeProvider theme={theme}>
              <Navbar />
              <div className={classes.content}>
              <Navigation />
              </div>
            </ThemeProvider>
          </div>
        </BrowserRouter>
      </Provider>
    </MsalProvider>
  );
}

export default App;
