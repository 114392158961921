export const gradients = [
  "#28bc35", // Green
  "#28bc35",
  "#51cc21",
  "#7ed71b",
  "#b2e114",
  "#f1d109",
  "#fc9b00",
  "#fc7e17",
  "#d85943",
  "#fb6929",
  "#fb4546", // red
];

export const typeOfLoads = ['Real Loads', 'Forecasted Loads', 'Bids', 'Offers']
export const pickUpLocations = ['Current Supplier', 'Location without a Trade', 'Opportunity']
export const shipToLocations = ['Current Customer', 'Location without a Trade', 'Opportunity']
