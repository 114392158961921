import _ from 'lodash'
import * as ActionTypes from './actionTypes'

const initialState = {
  loading: false,
  error: null,
  allShipTypes: null,
  allLocationTypes: null,
}

const reducer = (state = initialState, action) => {
  let newState = _.cloneDeep(state)

  switch (action.type) {
    case ActionTypes.BASE_API_LOADING:
      newState.loading = true
      return newState

    case ActionTypes.BASE_API_FAILED:
      newState.loading = false
      newState.error = action.payload
      return newState

    case ActionTypes.GET_ALL_SHIP_TYPES_SUCCESS:
      newState.loading = false
      newState.allShipTypes = action.payload.map((prod) => prod.Product)
      return newState

    case ActionTypes.GET_ALL_LOCATION_TYPES_SUCCESS:
      newState.loading = false
      newState.allLocationTypes = action.payload.map((type) => type.Type)
      return newState

    default:
      return state
  }
}

export default reducer
