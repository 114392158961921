import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  footer: {
    // height: 20,
    // marginTop: -30,
  },
  versionText: {
    fontSize: 10,
    color: '#999',
    marginLeft: 50,
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <footer class={classes.footer}>
      <div class={classes.versionText}>v. {process.env.REACT_APP_VERSION}</div>
    </footer>
  );
};

export default Footer;
