import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { useMsal } from '@azure/msal-react';

import logo from '../../assets/images/WWS-Logo.png';
import background from '../../assets/images/wws-background.png';
import RoundedButton from '../elements/RoundedButton';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
  },
  menuBar: {
    backgroundColor: theme.palette.primary.dark,
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 20,
    paddingBottom: 20,
  },
  mainPage: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  login: {
    padding: '35px 60px 50px',
    justifyContent: 'center',
  },
  title: {
    textAlign: 'center',
    marginTop: 0,
  },
  inputField: {
    width: 450,
  },
  btn: {
    width: 130,
    marginTop: 24,
  },
}));

const LoginScreen = ({ history }) => {
  const classes = useStyles();
  const { instance } = useMsal();

  const loginRequest = {
    scopes: ['User.Read'],
  };

  const login = () => {
    instance.loginPopup(loginRequest).catch((error) => console.log(error));
  };

  return (
    <Box maxWidth="false" className={classes.root}>
      <Box className={classes.menuBar}>
        <img src={logo} alt="WWS Trading" />
      </Box>
      <Box container spacing={1} className={classes.mainPage}>
        <Paper className={classes.login}>
          <h2 className={classes.title}>Sign in to your account</h2>
          <RoundedButton className={classes.btn} onClick={login}>
            Sign In
          </RoundedButton>
        </Paper>
      </Box>
    </Box>
  );
};

export default LoginScreen;
