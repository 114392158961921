import * as ActionTypes from './actionTypes'

import {
  findAllShipTypeList,
  findAllLocationType,
} from '../../repositories/ShipToLocationRepository';

export const baseApiLoading = () => ({
  type: ActionTypes.BASE_API_LOADING
})

export const baseApiFailed = (error) => ({
  type: ActionTypes.BASE_API_FAILED,
  paylaod: error
})

export const getAllShipTypes = () => {
  return (dispatch) => {
    dispatch(baseApiLoading())
    return findAllShipTypeList()
      .then(res => {
        dispatch({
          type: ActionTypes.GET_ALL_SHIP_TYPES_SUCCESS,
          payload: res
        })
      }).catch(error => {
        dispatch(baseApiFailed(error))
      })
  }
}

export const getAllLocationTypes = () => {
  return (dispatch) => {
    dispatch(baseApiLoading())
    return findAllLocationType()
      .then(res => {
        dispatch({
          type: ActionTypes.GET_ALL_LOCATION_TYPES_SUCCESS,
          payload: res
        })
      }).catch(error => {
        dispatch(baseApiFailed(error))
      })
  }
}
