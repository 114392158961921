import axios from 'axios';
import CityLocation from '../models/CityLocation';
import ProductCost from '../models/ProductCost';
import { DEFAULT_HEADERS } from './Shared';

import {
  CityBySupplierParams,
  PickupProductParams,
  PickupSupplierParams,
  PickupLocationParams,
} from './types';
import PickupLocation from '../models/PickupLocation';

export async function findAllSuppliers(
  product?: string
): Promise<Array<String>> {
  try {
    let params: PickupSupplierParams = {
      product,
    };
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/pickupSuppliers`,
      { headers: DEFAULT_HEADERS, params }
    );
    return response.data;
  } catch (err) {
    console.error(err);
    return [];
  }
}

export async function findCitiesBySupplier(
  vendorNo: string
): Promise<Array<CityLocation>> {
  let params: CityBySupplierParams = {
    supplier: vendorNo,
  };
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/pickupCityBySupplier`,
      { headers: DEFAULT_HEADERS, params }
    );
    return response.data;
  } catch (err) {
    console.error(err);
    return [];
  }
}

export async function findAllProducts(
  vendorNo?: string
): Promise<Array<String>> {
  try {
    let params: PickupProductParams = {};
    if (vendorNo) {
      params.supplier = vendorNo;
    }
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/pickupProducts`,
      { headers: DEFAULT_HEADERS, params }
    );

    return response.data;
  } catch (err) {
    console.error(err);
    return [];
  }
}

export async function findAllLocations(
  product?: string,
  forecastDate?: string,
  customerNo?: string,
  ship_location?: string,
): Promise<Array<PickupLocation>> {
  const url = `${process.env.REACT_APP_API_URL}/api/pickupLocations`;
  let params: PickupLocationParams = { product, customerNo, ship_location };
  if (forecastDate) {
    params.forecastDate = forecastDate;
  }
  try {
    const response = await axios.get(url, { headers: DEFAULT_HEADERS, params }
      );
    return response.data.map((location: PickupLocation) => {
      return {
        ...location,
        lastPickupDate: new Date(location.lastPickupDate)
      }
    });
  } catch (err) {
    return [];
  }
}

export async function findLocationsByProduct(
  product?: string,
): Promise<Array<PickupLocation>> {
  const url = `${process.env.REACT_APP_API_URL}/api/pickupLocationsByProduct`;
  let params: PickupLocationParams = { product };
  try {
    const response = await axios.get(url, { headers: DEFAULT_HEADERS, params }
      );
    return response.data;
  } catch (err) {
    return [];
  }
}

export async function costsOverTime(
  vendorNo: string,
  locationNo: string
): Promise<Array<ProductCost>> {
  const url = `${process.env.REACT_APP_API_URL}/api/costsOverTime`;
  const params = { vendorNo, locationNo };
  try {
    const response = await axios.get(url, { headers: DEFAULT_HEADERS, params }
      );
    return response.data;
  } catch (err) {
    return [];
  }
}
