import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { Bar, BarChart, CartesianGrid, YAxis, XAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import FutureLoadsDetail from './FutureLoadsDetail';
import { findAll, findAllDetails } from '../../../repositories/FutureLoadsRepository';

const FutureLoadsBarChart = ({ data, tooltip }) => (
  <ResponsiveContainer width="100%" height={400}>
    <BarChart data={data} barCategoryGap={30}>
      <CartesianGrid vertical={false} />
      <XAxis dataKey="date" />
      <YAxis axisLine={false} />
      <Tooltip content={tooltip} />
      <Legend verticalAlign="top" align="right" height={36} />
      <Bar dataKey="purchasedWeight" fill="#FB4546" />
      <Bar dataKey="soldWeight" fill="#28BC36" />
    </BarChart>
  </ResponsiveContainer>
)

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    minHeight: 500,
  },
  toolTip: {
    width: 240,
    backgroundColor: '#101C2A',
    color: 'white',
    padding: '6px 20px',
    borderRadius: '10px',
  },
  toolTipLabel: {
    fontSize: '16px',
    fontWeight: 'bold',
    textTransform: 'capitalize'
  },
  subheading: {
    color: theme.palette.text.secondary,
    paddingBottom: 20
  },
  centeredContent: {
    alignItems: 'center',
  }
}));

const FutureLoads = props => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [detailData, setDetailData] = useState([]);
  const [surplus, setSurplus] = useState([]);
  const [shortage, setShortage] = useState([]);

  useEffect(() => {
      fetchData();
      // const interval = setInterval(async () => {
      //   fetchData()
      // }, 30000);
      // return () => clearInterval(interval);
  }, []);

  const fetchData = async () => {
    setData(await findAll());
    const details = await findAllDetails();

    const summaryDetails = details.reduce((carry, detail) => {
        if (!carry[detail.type]) {
          carry[detail.type] = [];
        }
        carry[detail.type].push({
          date: detail.date,
          purchased: detail.purchased,
          sold: detail.sold,
        })
        return carry;
    }, {});

    setDetailData(summaryDetails);

    // calculate surplus and shortages
    setSurplus(details.filter((detail) => {
        return (detail.purchased > detail.sold)
    }).map((detail) => {
        return {
          type: detail.type,
          date: detail.date,
          surplus: detail.purchased - detail.sold
        }
    }));

    setShortage(details.filter((detail) => {
      return (detail.purchased < detail.sold)
    }).map((detail) => {
        return {
          type: detail.type,
          date: detail.date,
          shortage: detail.sold - detail.purchased
        }
    }));
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      const shortages = shortage.filter(item => item.date === label)
      const surpluses = surplus.filter(item => item.date === label)
      return (
        <div className={classes.toolTip}>
          {
            payload.map((item, index) => (
              <p key={index} className={classes.toolTipLabel}>{item.name}: {item.value}</p>    
            ))
          }
          {
            shortages.length > 0 && (
              <>
                <p className={classes.toolTipLabel}>Shortage</p>
                <Box>
                  {
                    shortages.map((item, index) => {
                      return (label === item.date)
                        ? <Box key={index} display="flex" justifyContent="space-between">
                            <Typography variant="body1">{ item.type }</Typography>
                            <Typography variant="body1">{ item.shortage }</Typography>
                          </Box>
                        : null 
                    })
                  }
                </Box>
              </>
            )
          }
          {
            surpluses.length > 0 && (
              <>
                <p className={classes.toolTipLabel}>Surplus</p>
                <Box>
                  {
                    surpluses.map((item, index) => {
                      return (label === item.date)
                        ? <Box key={index} display="flex" justifyContent="space-between">
                            <Typography variant="body1">{ item.type }</Typography>
                            <Typography variant="body1">{ item.surplus }</Typography>
                          </Box>
                        : null 
                    })
                  }
                </Box>
              </>
            )
          }
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      <Paper className={classes.paper}>
        <h2>Future Loads</h2>
        <div className={classes.subheading}>A look at next week's activity.</div>
        {data && data.length > 0 ? <FutureLoadsBarChart data={data} tooltip={<CustomTooltip />} />
        : <Grid container justify="center"><Grid item className={classes.centeredContent}><CircularProgress color="inherit" /></Grid></Grid>}
        {Object.entries(detailData).map((detail) => (
          <FutureLoadsDetail data={detail[1]} title={detail[0]} key={detail[0]}  />
        ))}
        
      </Paper>
    </div>
  );
};

export default FutureLoads;