import axios from 'axios';
import moment from 'moment';
import FutureLoad from '../models/FutureLoad';
import FutureLoadDetail from '../models/FutureLoadDetail';
import { DEFAULT_HEADERS } from './Shared';

export async function findAll(): Promise<Array<FutureLoad>> {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/futureLoads`, { headers: DEFAULT_HEADERS });
    return response.data && response.data.map((load: FutureLoad) => {
      return {...load, date: moment(load.date).format('MM/DD/YYYY')}
    });
  } catch (err) {
    return [];
  }
}

export async function findAllDetails(): Promise<Array<FutureLoadDetail>> {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/futureLoadDetails`, { headers: DEFAULT_HEADERS });
    return response.data;
  } catch (err) {
    console.error(err)
    return [];
  }
}