export const S2C_API_LOADING = 'S2C_API_LOADING'
export const S2C_API_FAILED = 'S2C_API_FAILED'

export const CHANGE_S2C_FILTERS = 'CHANGE_S2C_FILTERS'
export const CLEAR_S2C_DATA = 'CLEAR_S2C_DATA'

export const GET_S2C_LOCATIONS_SUCCESS = 'GET_S2C_LOCATIONS_SUCCESS'
export const GET_S2C_SUPPLIERS_SUCCESS = 'GET_S2C_SUPPLIERS_SUCCESS'
export const GET_S2C_PRODUCTS_SUCCESS = 'GET_S2C_PRODUCTS_SUCCESS'
export const GET_S2C_CITIES_SUCCESS = 'GET_S2C_CITIES_SUCCESS'

export const GET_S2C_COSTS_CHART_DATA = 'GET_S2C_COSTS_CHART_DATA'
export const GET_S2C_PRICES_CHART_DATA = 'GET_S2C_PRICES_CHART_DATA'
