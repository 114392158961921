import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import moment from 'moment';

import Select from '../../common/Select';
import LineChart from '../../common/LineChart';
import C2SFilter from './C2SFilter';
import PickupLocationsTable from './PickupLocationsTable';
import ShipToLocationsMap from '../tradingS2C/ShipToLocationsMap';
import LoadingWrapper from '../../common/LoadingWrapper';

import { getAllLocationTypes } from '../../../state/base/actions';
import {
  getC2SCustomers,
  getC2SCities,
  getC2SLocations,
  getC2SCostsChartData,
  getC2SPricesChartData
} from '../../../state/c2s/actions';

import { chartViewOptions } from '../../../utils/dummy';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '90%',
    margin: '0 auto',
  },
  paper: {
    padding: theme.spacing(2),
  },
  paperWrapper: {
    marginTop: theme.spacing(2),
  },
  header: {
    display: 'flex',
    borderBottom: '1px solid #ccc',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '& h2': {
      margin: 0,
    },
  },
}));

const TradingC2SScreen = ({
  allLocationTypes,
  customers,
  c2sFilters,
  markers,
  lines,
  costsChartData,
  pricesChartData,
  getAllLocationTypes,
  getC2SCustomers,
  getC2SLocations,
  getC2SCostsChartData,
  getC2SPricesChartData,
  baseLoading,
  c2sLoading,
}) => {
  const classes = useStyles();

  const [salesPriceType, setSalesPriceType] = useState('month');

  useEffect(() => {
    !allLocationTypes && getAllLocationTypes();
    !customers && getC2SCustomers();
    !costsChartData && getC2SCostsChartData();
    !pricesChartData && getC2SPricesChartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (c2sFilters.city && c2sFilters.product) {
      getC2SLocations(
        c2sFilters.product,
        c2sFilters.date,
        c2sFilters.customer.customerNo,
        c2sFilters.city.locationNo
      );
      getC2SCostsChartData(c2sFilters.customer.customerNo, c2sFilters.city.locationNo, c2sFilters.product);
      getC2SPricesChartData(c2sFilters.product, c2sFilters.date);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    c2sFilters.product,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    c2sFilters.date && moment(c2sFilters.date).format('MM/DD/YYYY'),
  ]);

  return (
    <LoadingWrapper visible={baseLoading || c2sLoading}>
      <div className={classes.container}>
        <Grid container spacing={3}>
          <Grid className={classes.paperWrapper} item xs={12}>
            <Paper className={classes.paper}>
              <Box className={classes.header}>
                <h2>Customer to Supplier Scenario</h2>
              </Box>
              <C2SFilter />
            </Paper>
          </Grid>
          <Grid className={classes.paperWrapper} item xs={12}>
            <Paper className={classes.paper}>
              <Box className={classes.header}>
                <h2>All Potential Pick Up Locations</h2>
              </Box>
              <PickupLocationsTable type="c2s" />
            </Paper>
          </Grid>
          <Grid className={classes.paperWrapper} item xs={6}>
            <Paper className={classes.paper}>
              <Box className={classes.header}>
                <h2>Pick Up Locations</h2>
              </Box>
              <ShipToLocationsMap markers={markers} lines={lines} />
            </Paper>
          </Grid>
          <Grid className={classes.paperWrapper} item xs={6}>
            <Paper className={classes.paper}>
              <Box className={classes.header}>
                <h2>Sales Price by Product</h2>
                <Select
                  label="Time"
                  value={salesPriceType}
                  onChange={(e) => setSalesPriceType(e.target.value)}
                  options={chartViewOptions}
                />
              </Box>
              <LineChart data={pricesChartData} />
            </Paper>
            <Paper className={`${classes.paperWrapper} ${classes.paper}`}>
              <Box className={classes.header}>
                <h2>Purchase cost by Location on Previous Trades</h2>
              </Box>
              <LineChart data={costsChartData} />
            </Paper>
          </Grid>
        </Grid>
      </div>
    </LoadingWrapper>
  );
};

const mapStateToProps = (state) => ({
  allLocationTypes: state.base.allLocationTypes,
  customers: state.c2s.customers,
  c2sFilters: state.c2s.filters,
  c2sLoading: state.c2s.loading,
  baseLoading: state.base.loading,
  markers: state.c2s.markers,
  lines: state.c2s.lines,
  costsChartData: state.c2s.costsChartData,
  pricesChartData: state.c2s.pricesChartData
});

const mapDispatchToProps = (dispatch) => ({
  getAllLocationTypes: () => {
    dispatch(getAllLocationTypes());
  },
  getC2SCustomers: () => {
    dispatch(getC2SCustomers());
  },
  getC2SCities: () => {
    dispatch(getC2SCities());
  },
  getC2SLocations: (product, date, location, customer) => {
    dispatch(getC2SLocations(product, date, location, customer));
  },
  getC2SPricesChartData: (product, fromDate, toDate) => {
    dispatch(getC2SPricesChartData(product, fromDate, toDate));
  },
  getC2SCostsChartData: (supplier, locationNo, product) => {
    dispatch(getC2SCostsChartData(supplier, locationNo, product));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TradingC2SScreen);
