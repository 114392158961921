import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { groupBy, debounce } from 'lodash';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import PlusIcon from '@material-ui/icons/AddOutlined';
import MinusIcon from '@material-ui/icons/RemoveOutlined';

import LoadingWrapper from '../../common/LoadingWrapper';
import LineChart from '../../common/LineChart';
import PricesLineChart from './PricesLineChart';
import S2CFilter from './S2CFilter';
import ShipToLocationsMap from './ShipToLocationsMap';
import ShipToLocationsTable from './ShipToLocationsTable';

import {
  getAllShipTypes,
  getAllLocationTypes,
} from '../../../state/base/actions';
import {
  changeS2CFilters,
  getS2CLocations,
  getS2CSuppliers,
  getS2CProducts,
  getS2CCities,
  getS2CCostsChartData,
  getS2CPricesChartData,
  clearS2CData,
} from '../../../state/s2c/actions';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '90%',
    margin: '0 auto',
  },
  paper: {
    padding: theme.spacing(2),
  },
  paperWrapper: {
    marginTop: theme.spacing(2),
    position: 'relative',
  },
  header: {
    display: 'flex',
    borderBottom: '1px solid #ccc',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '& h2': {
      margin: 0,
    },
  },
  zoomBtns: {
    position: 'absolute',
    bottom: '62px',
    left: '48%',
    background: 'rgb(0 0 0 / 3%)',
    '& button': {
      borderRadius: 'unset',
    },
  },
}));

const TradingS2CScreen = ({
  filters,
  allShipTypes,
  allLocationTypes,
  markers,
  lines,
  costsChartData,
  getAllShipTypes,
  getAllLocationTypes,
  getS2CLocations,
  getS2CSuppliers,
  getS2CProducts,
  getS2CCities,
  getS2CCostsChartData,
  getS2CPricesChartData,
  baseLoading,
  s2cLoading,
}) => {
  const classes = useStyles();
  const [headerList, setHeaderList] = useState([]);

  const [pricesChartData, setPricesChartData] = useState({
    customerNos: [],
    chartData: [],
  });
  const [month, setMonth] = useState(1);

  useEffect(() => {
    !allShipTypes && getAllShipTypes();
    !allLocationTypes && getAllLocationTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let tmpHeaders = [];
    if (filters.city) {
      tmpHeaders.push(filters.city.city);
    }
    if (filters.product) {
      tmpHeaders.push(filters.product);
    }
    setHeaderList(tmpHeaders);
    setMonth(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.city, filters.product]);

  useEffect(() => {
    debouncePricesByLocation(filters.product, month);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month]);

  useEffect(() => {
    getS2CSuppliers(filters.product);
    if (filters.product) {
      getS2CLocations(
        filters.product,
        filters.date?.format(),
        filters.supplier.supplier,
        filters.city.locationNo
      );
      getS2CPricesChartData(
        filters.product,
        moment().subtract(month, 'M').toDate(),
        moment().add(month, 'M').toDate()
      ).then((pricesData) => {
        processPricesData(pricesData);
      });
      getS2CCostsChartData(
        filters.supplier.supplier,
        filters.city.locationNo,
        filters.product
      );
    } else {
      clearS2CData({
        locations: [],
        markers: [],
        lines: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters.product,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    filters.date && moment(filters.date).format('MM/DD/YYYY'),
  ]);

  useEffect(() => {
    if (filters.supplier) {
      getS2CCities(filters.supplier.supplier);
    } else {
      clearS2CData({ cities: [] });
      changeS2CFilters('city', null);
    }

    filters.supplier && getS2CProducts(filters.supplier.supplier);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.supplier && filters.supplier.supplier]);

  // eslint-disable-next-line
  const debouncePricesByLocation = useCallback(
    debounce((product, m) => {
      if (product) {
        getS2CPricesChartData(
          product,
          moment().subtract(m, 'M').toDate(),
          moment().add(m, 'M').toDate()
        ).then((pricesData) => {
          processPricesData(pricesData);
        });
      }
    }, 700),
    []
  );

  // Format prices chart data
  const processPricesData = (pricesData) => {
    const customerNos = Object.keys(groupBy(pricesData, 'CustomerNo'));
    const chartData = [];

    const pricesDataGroupByDate = groupBy(pricesData, 'DeliveryDate');
    Object.keys(pricesDataGroupByDate).forEach((deliveryDate) => {
      const dataGroupByDate = pricesDataGroupByDate[deliveryDate];
      const dataByDate = {
        date: moment(deliveryDate).format('MM/DD/YYYY'),
      };
      customerNos.forEach((cn) => {
        const cnData = dataGroupByDate.filter((d) => d.CustomerNo === cn);
        if (cnData.length > 0) {
          dataByDate[`${cn}_forecast`] = moment().isSameOrBefore(deliveryDate)
            ? cnData[0].ForecastPrice
            : undefined;
          dataByDate[`${cn}_unit`] = moment().isSameOrAfter(deliveryDate)
            ? cnData[0].UnitPrice
            : undefined;
        } else {
          dataByDate[`${cn}_forecast`] = moment().isSameOrBefore(deliveryDate)
            ? 0
            : undefined;
          dataByDate[`${cn}_unit`] = moment().isSameOrAfter(deliveryDate)
            ? 0
            : undefined;
        }
        if (moment().isSame(deliveryDate, 'day')) {
          if (dataByDate[`${cn}_unit`] !== undefined) {
            dataByDate[`${cn}_forecast`] = dataByDate[`${cn}_unit`];
          } else if (dataByDate[`${cn}_forecast`] !== undefined) {
            dataByDate[`${cn}_unit`] = dataByDate[`${cn}_forecast`];
          }
        }
      });
      chartData.push(dataByDate);
    });
    setPricesChartData({ customerNos, chartData });
  };

  return (
    <LoadingWrapper visible={baseLoading || s2cLoading}>
      <div className={classes.container}>
        <Grid container spacing={3}>
          <Grid className={classes.paperWrapper} item xs={12}>
            <Paper className={classes.paper}>
              <Box className={classes.header}>
                <h2>Supplier to Customer Scenario</h2>
                {headerList.join(' + ')}
              </Box>
            </Paper>
            <S2CFilter />
          </Grid>
          <Grid className={classes.paperWrapper} item xs={12}>
            <Paper className={classes.paper}>
              <Box className={classes.header}>
                <h2>All Potential Ship To Locations</h2>
              </Box>
              <ShipToLocationsTable />
            </Paper>
          </Grid>
          <Grid className={classes.paperWrapper} item xs={6}>
            <Paper className={classes.paper}>
              <Box className={classes.header}>
                <h2>Ship To Locations</h2>
              </Box>
              <ShipToLocationsMap type="s2c" markers={markers} lines={lines} />
            </Paper>
          </Grid>
          <Grid className={classes.paperWrapper} item xs={6}>
            <Paper className={classes.paper}>
              <Box className={classes.header}>
                <h2>Purchase Cost by Product</h2>
              </Box>
              <LineChart data={costsChartData} />
            </Paper>
            <Paper className={`${classes.paperWrapper} ${classes.paper}`}>
              <Box className={classes.header}>
                <h2>Sales Price by Ship To Location</h2>
              </Box>
              <PricesLineChart prices={pricesChartData} />
              <Box
                display="flex"
                justifyContent="center"
                className={classes.zoomBtns}
              >
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => setMonth(month + 1)}
                >
                  <MinusIcon fontSize="small" />
                </IconButton>
                <IconButton
                  disabled={month === 1}
                  aria-label="delete"
                  size="small"
                  onClick={() => month > 1 && setMonth(month - 1)}
                >
                  <PlusIcon fontSize="small" />
                </IconButton>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </LoadingWrapper>
  );
};

const mapStateToProps = (state) => ({
  baseLoading: state.base.loading,
  s2cLoading: state.s2c.loading,
  filters: state.s2c.filters,
  markers: state.s2c.markers,
  lines: state.s2c.lines,
  costsChartData: state.s2c.costsChartData,
  allShipTypes: state.base.allShipTypes,
  allLocationTypes: state.base.allLocationTypes,
});

const mapDispatchToProps = (dispatch) => ({
  changeS2CFilters: (type, value) => {
    return dispatch(changeS2CFilters(type, value));
  },
  getAllShipTypes: () => {
    dispatch(getAllShipTypes());
  },
  getAllLocationTypes: () => {
    dispatch(getAllLocationTypes());
  },
  getS2CLocations: (product, forecastDate, supplier, locationNo) => {
    return dispatch(
      getS2CLocations(product, forecastDate, supplier, locationNo)
    );
  },
  getS2CSuppliers: (product) => {
    return dispatch(getS2CSuppliers(product));
  },
  getS2CProducts: (supplier) => {
    return dispatch(getS2CProducts(supplier));
  },
  getS2CCities: (supplier) => {
    return dispatch(getS2CCities(supplier));
  },
  getS2CCostsChartData: (supplier, locationNo, product) => {
    return dispatch(getS2CCostsChartData(supplier, locationNo, product));
  },
  getS2CPricesChartData: (product, fromDate, toDate) => {
    return dispatch(getS2CPricesChartData(product, fromDate, toDate));
  },
  clearS2CData: (data) => {
    return dispatch(clearS2CData(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TradingS2CScreen);
