import * as ActionTypes from './actionTypes';

import {
  findPotentialMatches,
  findPotentialMatchPickupProducts,
  findPotentialMatchShiptoProducts,
  findOptimizedMatches,
  findScenarios,
} from '../../repositories/OptimizationRepository';
import {
  findMatrixData,
  findProducts,
  addMatchExclusion,
  deleteMatchExclusion,
  findTruckingCompanies,
} from '../../repositories/MatrixRepository';

export const matrixApiLoading = () => ({
  type: ActionTypes.MATRIX_API_LOADING,
});

export const getPotentialMatches = (matrixFilters) => {
  return (dispatch) => {
    dispatch(matrixApiLoading());
    return findPotentialMatches(matrixFilters)
      .then((res) => {
        dispatch({
          type: ActionTypes.GET_MATRIX_POTENTIAL_MATCHES_SUCCESS,
          payload: res,
        });
        return Promise.resolve(res);
      })
      .catch((error) => {
        dispatch(matrixApiFailed(error));
      });
  };
};

export const getPickupProducts = () => {
  return (dispatch) => {
    return findPotentialMatchPickupProducts().then((res) => {
      dispatch({
        type: ActionTypes.GET_MATRIX_POTENTIAL_MATCH_PK_PRODS_SUCCESS,
        payload: res,
      });
      return Promise.resolve(res);
    });
  };
};

export const getMatrixProducts = () => {
  return (dispatch) => {
    return findProducts().then((res) => {
      dispatch({
        type: ActionTypes.GET_MATRIX_PRODS_SUCCESS,
        payload: res,
      });
      return Promise.resolve(res);
    });
  };
};

export const getScenarios = () => {
  return (dispatch) => {
    return findScenarios().then((res) => {
      dispatch({
        type: ActionTypes.GET_MATRIX_SCENARIOS,
        payload: res,
      });
      return Promise.resolve(res);
    });
  };
};

export const getShiptoProducts = () => {
  return (dispatch) => {
    return findPotentialMatchShiptoProducts().then((res) => {
      dispatch({
        type: ActionTypes.GET_MATRIX_POTENTIAL_MATCH_SP_PRODS_SUCCESS,
        payload: res,
      });
      return Promise.resolve(res);
    });
  };
};

export const getTruckingCompanies = () => {
  return (dispatch) => {
    return findTruckingCompanies().then((res) => {
      const truckingCompanies = res.map(r => r.TruckingCompany);
      dispatch({
        type: ActionTypes.GET_TRUCKING_COMPANIES_SUCCESS,
        payload: truckingCompanies,
      });
      return Promise.resolve(truckingCompanies);
    });
  };
};

export const getOptimizedMatches = (filters) => {
  return (dispatch) => {
    dispatch(matrixApiLoading());
    return findOptimizedMatches(filters)
      .then((res) => {
        dispatch({
          type: ActionTypes.GET_MATRIX_OPTIMIZED_MATCHES_SUCCESS,
          payload: res,
        });
        return Promise.resolve(res);
      })
      .catch((error) => {
        dispatch(matrixApiFailed(error));
      });
  };
};

export const clearOptimizedMatches = () => {
  console.log('CLEAR');
  return (dispatch) => {
    dispatch({
      type: ActionTypes.CLEAR_MATRIX_OPTIMIZED_MATCHES,
    });
    return Promise.resolve([]);
  };
};

export const getMatrixData = (filters) => {
  return (dispatch) => {
    dispatch(matrixApiLoading());
    return findMatrixData(filters)
      .then((res) => {
        dispatch({
          type: ActionTypes.GET_MATRIX_DATA,
          payload: res,
        });
        return Promise.resolve(res);
      })
      .catch((error) => {
        dispatch(matrixApiFailed(error));
      });
  };
};

export const matrixApiFailed = (error) => ({
  type: ActionTypes.MATRIX_API_FAILED,
  payload: error,
});

export const excludePontentialMatch = (potentialMatch) => {
  return (dispatch) => {
    return addMatchExclusion(potentialMatch)
      .then((res) => {
        dispatch({
          type: ActionTypes.POTENTIAL_MATCH_EXCLUDED_SUCCESS,
          payload: potentialMatch,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const includePontentialMatch = (potentialMatch) => {
  return (dispatch) => {
    return deleteMatchExclusion(potentialMatch)
      .then((res) => {
        dispatch({
          type: ActionTypes.POTENTIAL_MATCH_INCLUDED_SUCCESS,
          payload: potentialMatch,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
