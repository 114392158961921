import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import SortableTable from '../../common/SortableTable';
import { loadOffers, removeOffer } from '../../../state/bids/actions';

const headCells = [
  { id: 'Vendor', numeric: false, disablePadding: false, label: 'Vendor' },
  { id: 'PickupCity', numeric: false, disablePadding: false, label: 'City' },
  {
    id: 'PickupDate',
    numeric: false,
    disablePadding: false,
    label: 'Pick Date',
  },
  { id: 'Product', numeric: false, disablePadding: false, label: 'Product' },
  { id: 'Quantity', numeric: true, disablePadding: false, label: 'Quantity' },
  { id: 'Price', numeric: true, disablePadding: false, label: 'Price' },
  { id: 'PriceTerms', numeric: true, disablePadding: false, label: 'Terms' },
];

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginBottom: theme.spacing(3),
  },
  description: {
    color: theme.palette.text.secondary,
  },
  list: {
    display: 'flex',
    alignSelf: 'baseline',
    border: '1px solid #1991EB',
    padding: 0,
  },
  item: {
    backgroundColor: 'unset',
    borderLeft: '1px solid #1991EB',
    paddingTop: 0,
    paddingBottom: 0,
    color: '#1991EB',
    '&:first-child': {
      borderLeft: 'unset',
    },
  },
  activeItem: {
    backgroundColor: '#1991EB !important',
    color: 'white',
  },
  itemText: {
    fontSize: 11,
    whiteSpace: 'nowrap',
  },
}));

const RecentOffersTable = ({ loadOffers, offers, removeOffer }) => {
  const classes = useStyles();
  //   const [selectedIndex, setSelectedIndex] = useState(1);

  const handleDelete = (index) => {
    removeOffer(offers[index].ID);
  };

  useEffect(() => {
    loadOffers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={classes.top}>
        <div>
          <span className={classes.description}>
            Recent offers for quick reference
          </span>
        </div>
      </div>
      {offers && (
        <SortableTable
          headers={headCells}
          rows={offers}
          deletable={true}
          onDelete={handleDelete}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  offers: state.bids.offers,
});

const mapDispatchToProps = (dispatch) => ({
  loadOffers: () => {
    return dispatch(loadOffers());
  },
  removeOffer: (id) => {
    return dispatch(removeOffer(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RecentOffersTable);
