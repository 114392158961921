import axios from 'axios';
import { format } from 'date-fns';
import { DEFAULT_HEADERS } from './Shared';
import Offer from "../models/Offer";

export async function findAllOffers(): Promise<Array<Offer>> {
    const url = `${process.env.REACT_APP_API_URL}/api/findOffers`

    try {
      const response = await axios.get(url, { headers: DEFAULT_HEADERS });
      return response.data?.map((offer: Offer) => {
        return {
          ...offer,
          PriceTerms: offer.PriceTerms === 1 ? "FOB" : "Delivered",
          PickupDate: format(new Date(offer.PickupDate), "yyyy-MM-dd")
        }
      });
    } catch (err) {
      return [];
    }
}

export async function createOffer(offer: Offer): Promise<Number> {
    const url = `/api/createOffer`
    const instance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      timeout: 1000,
      headers: DEFAULT_HEADERS
    });
    try {
      const response = await instance.post(url, offer);
      console.log(response)
      return response.data;
    } catch (err) {
      return -1;
    }
}

export async function deleteOffer(id: number): Promise<Boolean> {
  const url = "/api/deleteOffer"
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 1000,
    headers: DEFAULT_HEADERS
  });
  try {
    const response = await instance.delete(url, {params: {id: id}});
    console.log(response)
    return true;
  } catch (err) {
    return false;
  }
}