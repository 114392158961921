import React from 'react';
import { connect } from 'react-redux'

import SortableTable from '../../common/SortableTable';

const headCells = [
  { id: 'Name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'cityState', numeric: true, disablePadding: false, label: 'City/State' },
  { id: 'lastDeliveryDate', numeric: true, disablePadding: false, label: 'Most Recent', type: 'date' },
  { id: 'lastUnitPrice', numeric: true, disablePadding: false, label: 'Most Recent Cost' },
  { id: 'lastTrade', numeric: false, disablePadding: false, label: 'Last Trade' },
  { id: 'Type', numeric: false, disablePadding: false, label: 'Location Type' },
  { id: 'Product', numeric: false, disablePadding: false, label: 'Product' },
  { id: 'prodVariant', numeric: false, disablePadding: false, label: 'Prod Variant' },
  { id: 'freightTomorrow', numeric: true, disablePadding: false, label: 'Freight Tomorrow' },
  { id: 'forecastTomorrow', numeric: true, disablePadding: false, label: 'Fcst Tomorrow' },
  { id: 'profitTomorrow', numeric: true, disablePadding: false, label: 'Profit Tomorrow' },
];

const ShipToLocationsTable = (props) => {
  const headers = props.forecastDate ?
    headCells.concat([{ id: 'freightFuture', numeric: true, disablePadding: false, label: `Frght ${props.forecastDate.format('M/DD/YY')}` },
    { id: 'forecastFuture', numeric: true, disablePadding: false, label: `Fcst ${props.forecastDate.format('M/DD/YY')}` },
    { id: 'profitFuture', numeric: true, disablePadding: false, label: `Profit ${props.forecastDate.format('M/DD/YY')}` }])
    : headCells;
  let rows = []
  if (props.locations) {
    rows = props.locations.map(l => {
      if(l.freightTomorrow !== null) l.freightTomorrow = parseFloat(l.freightTomorrow).toFixed(0)
      if(l.profitTomorrow !== null) l.profitTomorrow = parseFloat(l.profitTomorrow).toFixed(0)
      if(l.forecastTomorrow !== null) l.forecastTomorrow = parseFloat(l.forecastTomorrow).toFixed(4)
      return l
    })
  }
  return <SortableTable headers={headers} rows={rows}/>
}

const mapStateToProps = state => ({
  forecastDate: state.s2c.filters.date,
  locations: state.s2c.locations,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ShipToLocationsTable);