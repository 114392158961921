import axios from 'axios';
import ProductCost from '../models/ProductCost';
import { CostsOverTimeParams, CostsByLocationParams } from './types';
import { DEFAULT_HEADERS } from './Shared';

export async function findCostsByProduct(vendorNo: string,
    locationNo: string, product: string): Promise<Array<ProductCost>> {
        const params: CostsOverTimeParams = { vendorNo, locationNo, product }
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/costsOverTime`, { headers: DEFAULT_HEADERS, params });
        return response.data;
    } catch (err) {
        return [];
    }
}

export async function findCostsByLocation(product: string, fromDate: Date, toDate: Date): Promise<Array<ProductCost>> {
    const params: CostsByLocationParams = { product, fromDate, toDate }
try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/costsByLocation`, {  headers: DEFAULT_HEADERS, params });
    return response.data;
} catch (err) {
    return [];
}
}