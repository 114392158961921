import _ from 'lodash';
import * as ActionTypes from './actionTypes';

const initialState = {
  bids: null,
  offers: null,
};

const reducer = (state = initialState, action) => {
  let newState = _.cloneDeep(state);

  switch (action.type) {
    case ActionTypes.BID_LOAD_COMPLETE:
      newState.bids = action.payload;
      return newState;

    case ActionTypes.OFFER_LOAD_COMPLETE:
      newState.offers = action.payload;
      return newState;

    case ActionTypes.DELETE_BID_COMPLETE:
      newState.bids = state.bids.filter((bid) => bid.ID !== action.payload);
      return newState;

    case ActionTypes.DELETE_OFFER_COMPLETE:
      newState.offers = state.offers.filter(
        (offer) => offer.ID !== action.payload
      );
      return newState;

    default:
      return state;
  }
};

export default reducer;
