import _ from 'lodash';
import * as ActionTypes from './actionTypes';

const initialState = {
  loading: false,
  potentialMatches: null,
  pickupProducts: null,
  shiptoProducts: null,
  optimizedMatches: null,
  matrixProducts: null,
  matrixData: null,
  scenarios: null,
  truckingCompanies: null,
};

const matchMatches = (match1, match2) => {
  console.log('COMP', [match1, match2]);
  return (
    match1.pkVendor === match2.VendNo &&
    match1.pkAddress === match2.OrdNo &&
    match1.pkProduct === match2.SalesProduct &&
    match1.spCustomer === match2.CustNo &&
    match1.spAddress === match2.ShipNo &&
    match1.spProduct === match2.PurchProduct
  );
};

const reducer = (state = initialState, action) => {
  let newState = _.cloneDeep(state);

  switch (action.type) {
    case ActionTypes.GET_MATRIX_POTENTIAL_MATCHES_SUCCESS:
      newState.loading = false;
      newState.potentialMatches = action.payload;
      return newState;

    case ActionTypes.GET_MATRIX_POTENTIAL_MATCH_PK_PRODS_SUCCESS:
      newState.pickupProducts = action.payload;
      return newState;

    case ActionTypes.GET_MATRIX_POTENTIAL_MATCH_SP_PRODS_SUCCESS:
      newState.shiptoProducts = action.payload;
      return newState;

    case ActionTypes.GET_TRUCKING_COMPANIES_SUCCESS:
      newState.truckingCompanies = action.payload;
      return newState;

    case ActionTypes.MATRIX_API_FAILED:
      newState.loading = false;
      return newState;

    case ActionTypes.MATRIX_API_LOADING:
      newState.loading = true;
      return newState;

    case ActionTypes.GET_MATRIX_OPTIMIZED_MATCHES_SUCCESS:
      newState.loading = false;
      newState.optimizedMatches = action.payload;
      return newState;

    case ActionTypes.GET_MATRIX_DATA:
      newState.loading = false;
      newState.matrixData = action.payload;
      return newState;

    case ActionTypes.GET_MATRIX_SCENARIOS:
      newState.scenarios = action.payload;
      return newState;

    case ActionTypes.GET_MATRIX_PRODS_SUCCESS:
      newState.matrixProducts = action.payload;
      return newState;

    case ActionTypes.CLEAR_MATRIX_OPTIMIZED_MATCHES:
      newState.optimizedMatches = [];
      return newState;

    case ActionTypes.POTENTIAL_MATCH_EXCLUDED_SUCCESS:
      console.log('PAYLOAD', action.payload);
      newState.potentialMatches = state.potentialMatches.map((match) => {
        return {
          ...match,
          excluded: matchMatches(match, action.payload) ? 1 : match.excluded,
        };
      });
      return newState;

    case ActionTypes.POTENTIAL_MATCH_INCLUDED_SUCCESS:
      newState.potentialMatches = state.potentialMatches.map((match) => {
        return {
          ...match,
          excluded: matchMatches(match, action.payload) ? 0 : match.excluded,
        };
      });
      return newState;

    default:
      return state;
  }
};

export default reducer;
