import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Paper from '@material-ui/core/Paper';
import { DateRangePicker } from "react-date-range";
import moment from "moment";
import InputAdornment from '@material-ui/core/InputAdornment';
import CalendarIcon from '@material-ui/icons/DateRangeOutlined';

import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '33.3%',
    padding: theme.spacing(1),
    minWidth: 120,
    boxSizing: 'border-box',
    position: 'relative',
  },
  dateRangeWrap: {
    position: 'absolute',
    top: 68,
    right: theme.spacing(1),
    visibility: 'hidden',
    zIndex: 99,
  },
  dateRangeWrapOpened: {
    visibility: 'visible'
  },
  dateRangeLeftAlign: {
    right: 'unset',
    left: theme.spacing(1)
  }
}));

const CustomDateRangePicker = (props) => {
  const classes = useStyles();
  const dtRangeInputRef = useRef();
  const dtRangeCalendarRef = useRef();
  const [isDateRangeOpened, openDateRange] = useState(false)
  const [dateRange, setDateRange] = useState({
    ...props.value,
    key: 'selection',
    disabled: false,
    isSelected: true,
  })

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideCalendar)
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideCalendar)
    }
  }, [])

  const handleClickOutsideCalendar = (event) => {
    if ((dtRangeInputRef.current && dtRangeInputRef.current.contains(event.target)) || 
    (dtRangeCalendarRef.current && dtRangeCalendarRef.current.contains(event.target))) {
      openDateRange(true)
    } else {
      openDateRange(false)
    }
  }

  const onChangeDateRange = (item) => {
    setDateRange({ ...item.selection, isSelected: true })
    props.onChange({
      startDate: item.selection.startDate,
      endDate: item.selection.endDate
    })
  }

  return (
    <FormControl className={`${classes.formControl} ${props.className}`}>
      <TextField 
        ref={dtRangeInputRef} 
        label={props.label} 
        variant="outlined" 
        value={
          dateRange.isSelected
            ? moment(dateRange.startDate).format('MM/DD/YYYY') +
              ' - ' +
              moment(dateRange.endDate).format('MM/DD/YYYY')
            : ''
        }
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CalendarIcon style={{fill: '#939393'}} />
            </InputAdornment>
          ),
        }}
      />
      <div ref={dtRangeCalendarRef} className={`${classes.dateRangeWrap} ${isDateRangeOpened && classes.dateRangeWrapOpened} ${props.left && classes.dateRangeLeftAlign}`}>
        <Paper>
          <DateRangePicker
            className="date-range-picker"
            onChange={(item) => onChangeDateRange(item)}
            showSelectionPreview={false}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={[dateRange]}
            direction="horizontal"
            showDateDisplay={false}
            inputRanges={[]}
            // maxDate={new Date()}
          />
        </Paper>
      </div>
    </FormControl>
  );
};

export default CustomDateRangePicker;
