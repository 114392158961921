import axios from 'axios';
import SalesPrice from '../models/SalesPrice';
import ShipToLocation from '../models/ShipToLocation';
import ShipToCustomer from '../models/ShipToCustomer';
import { ShipToLocationParams, ShipToProductParams } from './types';
import CityLocation from '../models/CityLocation';
import { DEFAULT_HEADERS } from './Shared';

export async function findAllLocations(
  product?: string,
  forecastDate?: string,
  supplier?: string,
  pickup_location?: string
): Promise<Array<ShipToLocation>> {
  let url = `${process.env.REACT_APP_API_URL}/api/shipToLocations`;
  let params: ShipToLocationParams = {};
  if (product) {
    params.product = product;
  }
  if (forecastDate) {
    params.forecast_date = forecastDate;
  }
  if (supplier) {
    params.supplier = supplier;
  }
  if (pickup_location) {
    params.pickup_location = pickup_location;
  }

  try {
    const response = await axios.get(url, { headers: DEFAULT_HEADERS, params });
    return response.data.map((location: ShipToLocation) => {
      return {
        ...location,
        lastDeliveryDate: new Date(location.lastDeliveryDate)
      }
    });
  } catch (err) {
    return [];
  }
}

export async function findLocationsByProduct(
  product?: string
): Promise<Array<ShipToLocation>> {
  let url = `${process.env.REACT_APP_API_URL}/api/shipToLocationsByProduct`;
  let params: ShipToLocationParams = {};
  if (product) {
    params.product = product;
  }

  try {
    const response = await axios.get(url, { headers: DEFAULT_HEADERS, params });
    return response.data;
  } catch (err) {
    return [];
  }
}

export async function findAllProducts(
  customerNo?: string,
  locationNo?: string
): Promise<Array<String>> {
  try {
    let params: ShipToProductParams = {};
    if (customerNo) {
      params.customerNo = customerNo;
    }
    if (locationNo) {
      params.locationNo = locationNo;
    }
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/shipToProducts`,
      {  headers: DEFAULT_HEADERS, params }
    );
    return response.data;
  } catch (err) {
    console.error(err);
    return [];
  }
}

export async function findAllCustomers(): Promise<Array<ShipToCustomer>> {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/shipToCustomers`,
      { headers: DEFAULT_HEADERS }
    );
    return response.data;
  } catch (err) {
    console.error(err);
    return [];
  }
}

export async function findAllShipTypeList(): Promise<Array<String>> {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/shipToProducts`,
      { headers: DEFAULT_HEADERS }
    );
    return response.data;
  } catch (err) {
    console.error(err);
    return [];
  }
}

export async function findAllLocationType(): Promise<Array<String>> {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/shipToLocationTypes`
      , { headers: DEFAULT_HEADERS }
    );
    return response.data;
  } catch (err) {
    console.error(err);
    return [];
  }
}

export async function findCitiesByCustomer(
  customerNo: string
): Promise<Array<CityLocation>> {
  try {
    const params = { customerNo };
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/shipToCityByCustomer`,
      {  headers: DEFAULT_HEADERS, params }
    );
    return response.data;
  } catch (err) {
    console.error(err);
    return [];
  }
}

export async function pricesOverTime(
  customerNo: string,
  locationNo: string
): Promise<Array<SalesPrice>> {
  const url = `${process.env.REACT_APP_API_URL}/api/pricesOverTime`;
  const params = { customerNo, locationNo };
  try {
    const response = await axios.get(url, {  headers: DEFAULT_HEADERS, params });
    return response.data;
  } catch (err) {
    return [];
  }
}
