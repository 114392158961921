import { gradients } from './const';

export const findDotColor = (min, max, value) => {
  const percent = (value - min) / (max - min);
  return gradients[Math.floor(percent * 10)];
};

export const calculateDefaultAvtarBg = (name) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
};
export const intoRGB = (i) => {
  const c = (i & 0x00ffffff).toString(16).toUpperCase();
  return '00000'.substring(0, 6 - c.length) + c;
};
export const getDynamicHexColorFromText = (text) => {
  const color = `#${intoRGB(calculateDefaultAvtarBg(text))}`;
  return color;
};
