import axios from 'axios';
import { DEFAULT_HEADERS } from './Shared';
import Bid from "../models/Bid";
import { format } from 'date-fns';

export async function findAllBids(): Promise<Array<Bid>> {
    const url = `${process.env.REACT_APP_API_URL}/api/findBids`

    try {
      const response = await axios.get(url, { headers: DEFAULT_HEADERS });
      return response.data?.map((bid: Bid) => {
        return {
          ...bid,
          PriceTerms: bid.PriceTerms === 1 ? "FOB" : "Delivered",
          DeliveryDate: format(new Date(bid.DeliveryDate), "yyyy-MM-dd")
        }
      });
    } catch (err) {
      console.log('ERR', err)
      return [];
    }
}

export async function createBid(bid: Bid): Promise<Number> {
    const url = `/api/createBid`
    const instance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      timeout: 1000,
      headers: DEFAULT_HEADERS
    });
    try {
      const response = await instance.post(url, bid);
      return response.data;
    } catch (err) {
      return -1;
    }
}

export async function deleteBid(id: number): Promise<Boolean> {
    const url = `/api/deleteBid?id=${id}`
    const instance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      timeout: 1000,
      headers: DEFAULT_HEADERS
    });
    try {
      const response = await instance.get(url);
      console.log(response)
      return true;
    } catch (err) {
      return false;
    }
}