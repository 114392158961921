import * as ActionTypes from './actionTypes';

import { findAllLocations } from '../../repositories/ShipToLocationRepository';
import { findPricesByLocation } from '../../repositories/PriceRepository';
import { findCostsByProduct } from '../../repositories/CostsRepository';

import {
  findAllSuppliers,
  findAllProducts,
  findCitiesBySupplier,
} from '../../repositories/PickupLocationRepository';

import {
  locationsToMapMarkers,
  locationsToMapLines,
} from '../../services/LocationTransformer';

export const s2cApiLoading = () => ({
  type: ActionTypes.S2C_API_LOADING,
});

export const s2cApiFailed = (error) => ({
  type: ActionTypes.S2C_API_FAILED,
  payload: error,
});

export const changeS2CFilters = (type, value) => ({
  type: ActionTypes.CHANGE_S2C_FILTERS,
  payload: {
    type,
    value,
  },
});

export const clearS2CData = (data) => ({
  type: ActionTypes.CLEAR_S2C_DATA,
  payload: data,
});

export const getS2CLocations = (
  product,
  forecastDate,
  supplier,
  locationNo
) => {
  return (dispatch, getState) => {
    dispatch(s2cApiLoading());
    return findAllLocations(product, forecastDate, supplier, locationNo)
      .then((locs) => {
        let locations = [];
        let markers = [];
        let lines = [];
        if (locs) {
          locations = locs.map((l) => {
            // TODO: remove this map and handle date for sorting
            return {
              ...l,
              lastDeliveryDate: `${
                l.lastDeliveryDate?.getMonth() + 1
              }/${l.lastDeliveryDate?.getDate()}/${l.lastDeliveryDate?.getFullYear()}`,
            };
          });
          markers = locationsToMapMarkers(locs);
          const city = getState().s2c.filters.city;
          if (city) {
            lines = locationsToMapLines(
              { lon: city.Longitude, lat: city.Latitude },
              locs
            );
          }
        }
        dispatch({
          type: ActionTypes.GET_S2C_LOCATIONS_SUCCESS,
          payload: {
            locations,
            markers,
            lines,
          },
        });
        return Promise.resolve(locs);
      })
      .catch((error) => {
        dispatch(s2cApiFailed(error));
      });
  };
};

export const getS2CSuppliers = (product) => {
  return (dispatch) => {
    dispatch(s2cApiLoading());
    return findAllSuppliers(product)
      .then((res) => {
        dispatch({
          type: ActionTypes.GET_S2C_SUPPLIERS_SUCCESS,
          payload: res,
        });
        return Promise.resolve(res);
      })
      .catch((error) => {
        dispatch(s2cApiFailed(error));
      });
  };
};

export const getS2CProducts = (supplier) => {
  return (dispatch) => {
    dispatch(s2cApiLoading());
    return findAllProducts(supplier)
      .then((prods) => {
        let products = [];
        products = prods && prods.map((prod) => prod.Product);
        dispatch({
          type: ActionTypes.GET_S2C_PRODUCTS_SUCCESS,
          payload: products,
        });
        return Promise.resolve(prods);
      })
      .catch((error) => {
        dispatch(s2cApiFailed(error));
      });
  };
};

export const getS2CCities = (supplier) => {
  return (dispatch) => {
    dispatch(s2cApiLoading());
    return findCitiesBySupplier(supplier)
      .then((cities) => {
        dispatch({
          type: ActionTypes.GET_S2C_CITIES_SUCCESS,
          payload: cities,
        });
        return Promise.resolve(cities);
      })
      .catch((error) => {
        dispatch(s2cApiFailed(error));
      });
  };
};

export const getS2CCostsChartData = (supplier, locationNo, product) => {
  return (dispatch) => {
    dispatch(s2cApiLoading());
    return findCostsByProduct(supplier, locationNo, product)
      .then((costs) => {
        let costsChartData = [];
        costsChartData =
          costs &&
          costs.map((cost) => {
            return {
              date: cost.PickupDate,
              Real: cost.UnitCost,
              Projected: cost.ForecastCost,
            };
          });
        dispatch({
          type: ActionTypes.GET_S2C_COSTS_CHART_DATA,
          payload: costsChartData,
        });
        return Promise.resolve(costs);
      })
      .catch((error) => {
        dispatch(s2cApiFailed(error));
      });
  };
};

export const getS2CPricesChartData = (product, fromDate, toDate) => {
  return (dispatch) => {
    dispatch(s2cApiLoading());
    return findPricesByLocation(product, fromDate, toDate)
      .then((res) => {
        dispatch({
          type: ActionTypes.GET_S2C_PRICES_CHART_DATA,
          payload: res,
        });
        return Promise.resolve(res);
      })
      .catch((error) => {
        dispatch(s2cApiFailed(error));
      });
  };
};
