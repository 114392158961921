import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import FilterField from '../../common/FilterField';
import DateRangePicker from '../../common/DateRangePicker';

import { typeList } from '../../../utils/dummy';

const useStyles = makeStyles((theme) => ({
  box: {
    textAlign: 'right',
    margin: -theme.spacing(1),
    paddingTop: theme.spacing(1),
    borderTop: '1px solid #ccc',
  },
  filterField: {
    width: '25%',
  },
  filterBtn: {
    minWidth: 160,
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const MatrixFilter = ({filters, changeFilters, hide, pickupProducts, shiptoProducts}) => {
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <Box>
        <FilterField
          label="Pickup Product"
          choices={pickupProducts || []}
          value={filters.pkProduct}
          onChange={(e, value) => changeFilters('pkProduct', value)}
        />
        <FilterField
          label="Ship To Product"
          choices={shiptoProducts || []}
          value={filters.freight}
          onChange={(e, value) => changeFilters('spProduct', value)}
        />
        <DateRangePicker
          label="Pickup Date Range"
          value={filters.dropOffDate}
          onChange={(value) => changeFilters('pkDate', value)}
        />
        <DateRangePicker
          className={classes.filterField}
          label="Delivery Date Range"
          left
          value={filters.pickUpDate}
          onChange={(value) => changeFilters('deliveryDate', value)}
        />
        <FilterField
          className={classes.filterField}
          label="Type of Load"
          choices={typeList}
          value={filters.location}
          onChange={(e, value) => changeFilters('loadType', value)}
        />
        <FilterField
          className={classes.filterField}
          label="Type of Pickup Location"
          choices={typeList}
          value={filters.city}
          onChange={(e, value) => changeFilters('pkLocationType', value)}
        />
        <FilterField
          className={classes.filterField}
          label="Type of Ship To Location"
          choices={typeList}
          value={filters.tradeNo}
          onChange={(e, value) => changeFilters('spLocationType', value)}
        />
      </Box>
      <Button
        className={classes.filterBtn}
        color="secondary"
        onClick={hide}
      >
        Collapse Filters
      </Button>
    </Box>
  );
};

const mapStateToProps = state => ({
  pickupProducts: state.matrix.pickupProducts,
  shiptoProducts: state.matrix.shiptoProducts,
});

export default connect(mapStateToProps) (MatrixFilter);
