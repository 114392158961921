import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { DataGrid } from '@material-ui/data-grid';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    padding: spacing(2)
  },
  grid: {
    border: 'unset',
    '& .MuiDataGrid-colCellWrapper': {
      backgroundColor: '#F9FAFC',
    }
  }
}));

const columns = [
  { field: 'pkLocation', headerName: 'Pickup Location', width: 180},
  { field: 'pkCity', headerName: 'Pk City', width: 120},
  { field: 'pkProduct', headerName: 'Pk Prod', width: 90},
  { field: 'pkVariant', headerName: 'Pk Var', width: 100},
  { field: 'pkDate', headerName: 'Pickup Date', width: 100},
  // { field: 'pkLocationType', headerName: 'Pk Type', width: 150},
  { field: 'purchaseCost', headerName: 'Cost', width: 90},
  { field: 'spLocation', headerName: 'Ship To Location', width: 180},
  { field: 'spCity', headerName: 'Ship City', width: 120},
  { field: 'spProduct', headerName: 'Sh Prod', width: 90},
  { field: 'spVariant', headerName: 'Sh Var', width: 100},
  { field: 'deliveryDate', headerName: 'Delivery Date', width: 100},
  // { field: 'spLocationType', headerName: 'Sh Type', width: 100},
  // { field: 'loadType', headerName: 'Load Type', width: 150},
  { field: 'salesPrice', headerName: 'Price', width: 90},
  { field: 'freightCost', headerName: 'Freight', width: 100},
  { field: 'estimatedProfit', headerName: 'Est. Profit', width: 100},
  { field: 'tradeNum', headerName: 'Trade Number, if available', width: 150},
];


export default function DataTable(props) {
  const classes = useStyles()

  return (props.data ?
    <Paper className={classes.root}>
      <DataGrid className={classes.grid} rows={props.data} columns={columns} autoHeight={true} pageSize={100} />
    </Paper> : null
  );
}
