import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import Alert from '@material-ui/lab/Alert';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import { connect } from 'react-redux';

import Dialog from './Dialog';
import FilterField from './FilterField';
import DateSinglePicker from './DateSinglePicker';
import { createBid } from '../../repositories/BidsRepository';
import { createOffer } from '../../repositories/OffersRepository';
import { loadOffers } from '../../state/bids/actions';
import { loadBids } from '../../state/bids/actions';

import {
  findAllProducts,
  findAllCustomers,
  findCitiesByCustomer,
} from '../../repositories/ShipToLocationRepository';
import {
  findAllSuppliers,
  findCitiesBySupplier,
} from '../../repositories/PickupLocationRepository';

const useStyles = makeStyles((theme) => ({
  dialog: {
    maxWidth: 860,
    overflowY: 'visible',
  },
  title: {
    '& h2': {
      fontSize: 23,
      fontWeight: 'bold',
      textAlign: 'center',
      marginTop: theme.spacing(2.5),
    },
  },
  close: {
    position: 'absolute',
    top: theme.spacing(1.5),
    left: theme.spacing(2),
  },
  actions: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& button': {
      minWidth: 130,
      marginBottom: theme.spacing(2),
      marginLeft: 0,
    },
  },
  formControl: {
    width: '50%',
    padding: theme.spacing(1),
    minWidth: 120,
    boxSizing: 'border-box',
  },
  halfField: {
    width: '50%',
  },
  fullField: {
    width: '100%',
  },
}));

const termsList = [
  { id: 1, description: 'FOB' },
  { id: 2, description: 'Delivered' },
];

const AddBidDialog = ({ loadBids, loadOffers, handleClose, open }) => {
  const classes = useStyles();
  const [bidInfo, setBidInfo] = useState({
    customer: '',
    city: '',
    date: null,
    product: '',
    quantity: '',
    price: '',
    cost: '',
    vendor: '',
    terms: '',
    notes: '',
  });
  const [productList, setProductList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [message, setMessage] = useState('');
  const [type, setType] = useState('bid');

  useEffect(() => {
    const fetchData = async () => {
      if (bidInfo.customer) {
        findCitiesByCustomer(bidInfo.customer.customerNo).then((cities) => {
          if (cities) {
            setCityList(cities);
          }
        });
      } else {
        setCityList([]);
      }
    };

    fetchData();
  }, [bidInfo.customer]);

  useEffect(() => {
    const fetchData = async () => {
      if (bidInfo.vendor) {
        findCitiesBySupplier(bidInfo.vendor.supplier).then((cities) => {
          if (cities) {
            console.log('CITIES', cities);
            setCityList(cities);
          }
        });
      } else {
        setCityList([]);
      }
    };

    fetchData();
  }, [bidInfo.vendor]);

  useEffect(() => {
    findAllProducts().then((prods) => {
      prods && setProductList(prods.map((prod) => prod.Product));
    });

    findAllCustomers().then(
      (customers) => customers && setCustomerList(customers)
    );

    findAllSuppliers().then((vendors) => vendors && setVendorList(vendors));
  }, []);

  const changeField = (type, value) => {
    setBidInfo({
      ...bidInfo,
      [type]: value,
    });
  };

  const onSave = () => {
    setMessage(null);
    // first save
    if (type === 'bid') {
      createBid({
        ...bidInfo,
        customer: bidInfo.customer?.customerNo,
        city: bidInfo.city?.cityState,
        terms: bidInfo.terms.id,
      }).then((result) => {
        if (result !== -1) {
          loadBids();
          onClose();
        } else {
          setMessage('An error occurred during save.');
        }
      });
    } else {
      createOffer({
        ...bidInfo,
        customer: bidInfo.vendor?.supplier,
        city: bidInfo.city?.city,
        terms: bidInfo.terms.id,
      }).then((result) => {
        if (result !== -1) {
          loadOffers();
          onClose();
        } else {
          setMessage('An error occurred during save.');
        }
      });
    }
  };

  const handleType = (event, newType) => {
    setType(newType);
  };

  const onClose = () => {
    setBidInfo({
      customer: '',
      city: '',
      date: null,
      product: '',
      terms: '',
      quantity: '',
      price: '',
      cost: '',
      vendor: '',
      notes: '',
    });
    setMessage(null);
    handleClose();
  };

  const bidForm = () => {
    return (
      <>
        <FilterField
          className={classes.halfField}
          label="Customer"
          labelKey="Name"
          choices={customerList}
          value={bidInfo.customer}
          onChange={(e, value) => changeField('customer', value)}
        />
        <FilterField
          className={classes.halfField}
          label="Delivery City"
          labelKey="cityState"
          choices={cityList}
          value={bidInfo.city}
          onChange={(e, value) => changeField('city', value)}
        />
        <DateSinglePicker
          className={classes.halfField}
          value={bidInfo.date}
          onChange={(value) => changeField('date', value)}
        />
        <FilterField
          className={classes.halfField}
          label="Product"
          choices={productList}
          value={bidInfo.product}
          onChange={(e, value) => changeField('product', value)}
        />
        <FormControl className={classes.formControl}>
          <TextField
            label="Quantity"
            variant="outlined"
            value={bidInfo.quantity}
            onChange={(e) => changeField('quantity', e.target.value)}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            label="Price"
            variant="outlined"
            value={bidInfo.price}
            onChange={(e) => changeField('price', e.target.value)}
          />
        </FormControl>
        <FilterField
          className={classes.halfField}
          label="Offer Terms"
          labelKey="description"
          choices={termsList}
          value={bidInfo.terms}
          onChange={(e, value) => changeField('terms', value)}
        />
        <FormControl className={classes.formControl}>
          <TextField
            label="Notes"
            variant="outlined"
            multiline={true}
            value={bidInfo.notes}
            onChange={(e) => changeField('notes', e.target.value)}
          />
        </FormControl>
      </>
    );
  };
  const offerForm = () => {
    return (
      <>
        <FilterField
          className={classes.halfField}
          label="Vendor"
          labelKey="Supplier Name"
          choices={vendorList}
          value={bidInfo.vendor}
          onChange={(e, value) => changeField('vendor', value)}
        />
        <FilterField
          className={classes.halfField}
          label="Pick City"
          labelKey="city"
          choices={cityList}
          value={bidInfo.city}
          onChange={(e, value) => changeField('city', value)}
        />
        <DateSinglePicker
          className={classes.halfField}
          value={bidInfo.date}
          onChange={(value) => changeField('date', value)}
        />
        <FilterField
          className={classes.halfField}
          label="Product"
          choices={productList}
          value={bidInfo.product}
          onChange={(e, value) => changeField('product', value)}
        />
        <FormControl className={classes.formControl}>
          <TextField
            label="Quantity"
            variant="outlined"
            value={bidInfo.quantity}
            onChange={(e) => changeField('quantity', e.target.value)}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            label="Price"
            variant="outlined"
            value={bidInfo.price}
            onChange={(e) => changeField('price', e.target.value)}
          />
        </FormControl>
        <FilterField
          className={classes.halfField}
          label="Offer Terms"
          labelKey="description"
          choices={termsList}
          value={bidInfo.terms}
          onChange={(e, value) => changeField('terms', value)}
        />
        <FormControl className={classes.formControl}>
          <TextField
            label="Notes"
            variant="outlined"
            multiline={true}
            value={bidInfo.notes}
            onChange={(e) => changeField('notes', e.target.value)}
          />
        </FormControl>
      </>
    );
  };

  return (
    <Dialog className={classes.dialog} open={open} onClose={onClose}>
      <DialogTitle classes={{ root: classes.title }}>
        Add New{' '}
        <ToggleButtonGroup
          value={type}
          exclusive
          onChange={handleType}
          aria-label="Bid or Offer"
        >
          <ToggleButton value="bid" aria-label="Bid">
            Bid
          </ToggleButton>
          <ToggleButton value="offer" aria-label="Offer">
            Offer
          </ToggleButton>
        </ToggleButtonGroup>
        <IconButton
          classes={{ root: classes.close }}
          aria-label="close"
          onClick={onClose}
          size="small"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {message && <Alert severity="warning">{message}</Alert>}
        {type === 'bid' ? bidForm() : offerForm()}
      </DialogContent>
      <DialogActions classes={{ root: classes.actions }}>
        <Button onClick={onSave} variant="contained" color="secondary">
          Save
        </Button>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch) => ({
  loadBids: () => {
    return dispatch(loadBids());
  },
  loadOffers: () => {
    return dispatch(loadOffers());
  },
});

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AddBidDialog);
