import React from 'react';
import {
  ComposableMap,
  Line,
  Geographies,
  Geography,
  Marker,
  ZoomableGroup,
} from 'react-simple-maps';
import moment from 'moment';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';

const geoUrl = 'https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json';

const buildOverlay = (marker) => {
  return (
    <div>
      {marker.key}
      <br />
      {marker.data?.cityState}
      <br />
      {marker.data?.lastTrade}
      {marker.data?.lastTrade && ' - '}
      {marker.data?.lastDate ? moment(marker.data?.lastDate).format('MM/DD/YYYY') : ''}
    </div>
  );
};

const UsMap = (props) => {
  return (
    <ComposableMap
      projection="geoAlbersUsa"
      height={props.height || 300}
      projectionConfig={{
        scale: 750,
      }}
    >
      <ZoomableGroup zoom={1}>
        <Geographies geography={geoUrl}>
          {({ geographies }) => (
            <>
              {geographies.map((geo) => (
                <Geography
                  key={geo.rsmKey}
                  stroke="#fff"
                  geography={geo}
                  // fill="#f6f7f9"
                  style={{
                    default: {
                      fill: '#f6f7f9',
                      outline: 'none',
                    },
                    hover: {
                      fill: '#f6f7f9',
                      outline: 'none',
                    },
                    pressed: {
                      fill: '#f6f7f9',
                      outline: 'none',
                    },
                  }}
                />
              ))}
            </>
          )}
        </Geographies>
        {props.markers && props.markers.length > 0
          ? props.markers
              .filter(
                (m) => m.coordinates.reduce((a, c) => a && c !== null),
                true
              )
              .map((marker, index) =>
                index < 500 ? (
                  <Marker key={index} coordinates={marker.coordinates}>
                    <Tooltip placement="top" overlay={buildOverlay(marker)}>
                      <g
                        key={index}
                        fill="none"
                        stroke={
                          props.colors
                            ? props.colors[marker.key]
                            : marker.color || 'red'
                        }
                        strokeWidth="1"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        {marker.type === 'triangle' ? (
                          <polygon
                            points="2,0 -2,0 0,-3"
                            fill={
                              props.colors
                                ? props.colors[marker.key]
                                : marker.color || 'red'
                            }
                          />
                        ) : (
                          <circle
                            cx="1"
                            cy="1"
                            r="2"
                            fill={
                              props.colors
                                ? props.colors[marker.key]
                                : marker.color || 'red'
                            }
                          />
                        )}
                      </g>
                    </Tooltip>
                  </Marker>
                ) : null
              )
          : null}
        {props.lines && props.lines.length > 0
          ? props.lines.map((line, index) => (
              <Line
                key={index}
                from={line.from}
                to={line.to}
                stroke={props.colors ? props.colors[line.key] : 'red'}
                strokeWidth={2}
              />
            ))
          : null}
      </ZoomableGroup>
    </ComposableMap>
  );
};

export default UsMap;
