import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import LockIcon from '@material-ui/icons/LockOpen';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  btn: {
    width: 100,
    marginTop: theme.spacing(1),
  },
  box: {
    minWidth: 290
  }
}));

const CellTooltipOverlay = ({ data, isExcluded, exclude }) => {
  const classes = useStyles();
  return (
    <Box>
      <Box className={classes.box} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">Trade Detail</Typography>
        <LockIcon />
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="body1">Estimated Freight Cost</Typography>
        <Typography variant="body1">{data.freightCost?.toFixed()}</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="body1">Estimated Profit</Typography>
        <Typography variant="body1">
          {data.estimatedProfit?.toFixed()}
        </Typography>
      </Box>
      {data.TruckingCompany && (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body1">Trucking Company</Typography>
          <Typography variant="body1">
            {data.TruckingCompany}
          </Typography>
        </Box>
      )}
      {data.trade ? (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body1">Trade</Typography>
          <Typography variant="body1">{data.trade}</Typography>
        </Box>
      ) : null}
      {/* <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="body1">Freight Company</Typography>
        <Typography variant="body1">XXX</Typography>
      </Box> */}
      <Button
        className={classes.btn}
        color="secondary"
        variant="contained"
        size="small"
        onClick={exclude}
      >
        {isExcluded ? 'Include' : 'Exclude'}
      </Button>
    </Box>
  );
};

export default CellTooltipOverlay;
