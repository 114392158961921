import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import FilterField from '../../common/FilterField';

const useStyles = makeStyles((theme) => ({
  box: {
    textAlign: 'right',
    margin: -theme.spacing(1),
    paddingTop: theme.spacing(1),
    borderTop: '1px solid #ccc',
  },
  filterField1: {
    width: '100%',
  },
  filterField: {
    width: '25%',
  },
  filterBtn: {
    minWidth: 160,
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  bottomBox: {
    padding: theme.spacing(1),
  },
}));

const MatrixFilter = ({
  filters,
  changeFilters,
  hide,
  pickupProducts,
  shiptoProducts,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <Box>
        <FilterField
          className={classes.filterField}
          label="Pick Up Product"
          choices={pickupProducts || []}
          value={filters.pickupProduct}
          onChange={(e, value) => changeFilters('pickupProduct', value)}
        />
        <FilterField
          className={classes.filterField}
          label="Ship To Product"
          choices={shiptoProducts || []}
          value={filters.shiptoProduct}
          onChange={(e, value) => changeFilters('shiptoProduct', value)}
        />
      </Box>
      <Box
        className={classes.bottomBox}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <FormControlLabel
          value="start"
          control={
            <Switch
              color="secondary"
              checked={filters.showExcluded}
              onChange={(e) => changeFilters('showExcluded', e.target.checked)}
            />
          }
          label="Show Excluded"
          labelPlacement="end"
        />
        <Button className={classes.filterBtn} color="secondary" onClick={hide}>
          Collapse Filters
        </Button>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  pickupProducts: state.matrix.pickupProducts,
  shiptoProducts: state.matrix.shiptoProducts,
});

export default connect(mapStateToProps)(MatrixFilter);
