import * as ActionTypes from './actionTypes';

import { findAllLocations } from '../../repositories/PickupLocationRepository';

import {
  findAllCustomers,
  findAllProducts,
  findCitiesByCustomer,
} from '../../repositories/ShipToLocationRepository';

import { findPricesByLocation } from '../../repositories/PriceRepository';
import { findCostsByProduct } from '../../repositories/CostsRepository';

import {
  locationsToMapMarkers,
  locationsToMapLines,
} from '../../services/LocationTransformer';

export const c2sApiLoading = () => ({
  type: ActionTypes.C2S_API_LOADING,
});

export const c2sApiFailed = (error) => ({
  type: ActionTypes.C2S_API_FAILED,
  payload: error,
});

export const changeC2SFilters = (type, value) => ({
  type: ActionTypes.CHANGE_C2S_FILTERS,
  payload: {
    type,
    value,
  },
});

export const clearC2SData = (data) => ({
  type: ActionTypes.CLEAR_C2S_DATA,
  payload: data,
});

export const getC2SLocations = (
  product,
  forecastDate,
  customer,
  locationNo
) => {
  return (dispatch, getState) => {
    dispatch(c2sApiLoading());
    return findAllLocations(product, forecastDate, customer, locationNo)
      .then((locs) => {
        let locations = [];
        let markers = [];
        let lines = [];
        if (locs) {
          locations = locs.map((l) => {
            // TODO: remove this map and handle date for sorting
            return {
              ...l,
              lastPickupDate: `${
                l.lastPickupDate?.getMonth() + 1
              }/${l.lastPickupDate?.getDate()}/${l.lastPickupDate?.getFullYear()}`,
            };
          });
          markers = locationsToMapMarkers(locs);
          const city = getState().c2s.filters.city;
          if (city) {
            lines = locationsToMapLines(
              { lon: city.Longitude, lat: city.Latitude },
              locs
            );
          }
        }
        dispatch({
          type: ActionTypes.GET_C2S_LOCATIONS_SUCCESS,
          payload: {
            locations,
            markers,
            lines,
          },
        });
        return Promise.resolve(locs);
      })
      .catch((error) => {
        console.log('ERROR', error);
        dispatch(c2sApiFailed(error));
      });
  };
};

export const getC2SCustomers = (product) => {
  return (dispatch) => {
    dispatch(c2sApiLoading());
    return findAllCustomers(product)
      .then((res) => {
        dispatch({
          type: ActionTypes.GET_C2S_CUSTOMERS_SUCCESS,
          payload: res,
        });
        return Promise.resolve(res);
      })
      .catch((error) => {
        dispatch(c2sApiFailed(error));
      });
  };
};

export const getC2SProducts = (customer) => {
  return (dispatch) => {
    dispatch(c2sApiLoading());
    return findAllProducts(customer)
      .then((prods) => {
        let products = [];
        products = prods && prods.map((prod) => prod.Product);
        dispatch({
          type: ActionTypes.GET_C2S_PRODUCTS_SUCCESS,
          payload: products,
        });
        return Promise.resolve(prods);
      })
      .catch((error) => {
        dispatch(c2sApiFailed(error));
      });
  };
};

export const getC2SCities = (customer) => {
  return (dispatch) => {
    dispatch(c2sApiLoading());
    return findCitiesByCustomer(customer)
      .then((cities) => {
        dispatch({
          type: ActionTypes.GET_C2S_CITIES_SUCCESS,
          payload: cities,
        });
        return Promise.resolve(cities);
      })
      .catch((error) => {
        dispatch(c2sApiFailed(error));
      });
  };
};

export const getC2SCostsChartData = (supplier, locationNo, product) => {
  return (dispatch) => {
    dispatch(c2sApiLoading());
    return findCostsByProduct(supplier, locationNo, product)
      .then((costs) => {
        let costsChartData = [];
        costsChartData =
          costs &&
          costs.map((cost) => {
            return {
              date: cost.PickupDate,
              Real: cost.UnitCost,
              Projected: cost.ForecastCost,
            };
          });
        dispatch({
          type: ActionTypes.GET_C2S_COSTS_CHART_DATA,
          payload: costsChartData,
        });
        return Promise.resolve(costs);
      })
      .catch((error) => {
        dispatch(c2sApiFailed(error));
      });
  };
};

export const getC2SPricesChartData = (product, fromDate, toDate) => {
  return (dispatch) => {
    dispatch(c2sApiLoading());
    return findPricesByLocation(product, fromDate, toDate)
      .then((prices) => {
        let pricesChartData = [];
        pricesChartData =
          prices &&
          prices.map((cost) => {
            return {
              date: cost.DeliveryDate,
              Real: cost.UnitPrice,
              Projected: cost.ForecastPrice,
            };
          });
        dispatch({
          type: ActionTypes.GET_C2S_PRICES_CHART_DATA,
          payload: pricesChartData,
        });
        return Promise.resolve(prices);
      })
      .catch((error) => {
        dispatch(c2sApiFailed(error));
      });
  };
};
